<script setup>
import StudentsManage from '../StudentsManage.vue'
import { getAuth } from '../func/auth';
import { ROUTER_PAGES } from '../func/routers';
import ErrorPage from '../ui/ErrorPage.vue';
import LoadingStamp from '../ui/LoadingStamp.vue'

const authData = getAuth()
</script>
<template>
    <Suspense v-if="authData.level <= ROUTER_PAGES['students'].perm">
        <StudentsManage/>
        <template #fallback>
            <LoadingStamp/>
        </template>
    </Suspense>
    <ErrorPage v-else :errorCode="403"/>
</template>