<script setup>
/* eslint-disable */
import LoginPage from '@/components/LoginPage.vue';
import { ref } from 'vue';
import { authLogout, chechAuthParams, getAuth, isValidAuthSession } from './components/func/auth.js';
import { getPage, ROUTER_PILLS, ROUTER_PAGES } from './components/func/routers';
import { getTheme, shortName, themeSwitch } from './components/func/const';
import InvitePage from './components/InvitePage.vue';
import LoadingStamp from './components/ui/LoadingStamp.vue';
import ErrorPage from './components/ui/ErrorPage.vue';
import { httpResponse } from './components/func/request';

const invite = chechAuthParams()
const status = ref(getPage())
const darkTheme = ref(getTheme())

const authValidation = await isValidAuthSession()
const authData = getAuth()
let adminData
if (authValidation.isValid) {
    // let testData = (await httpResponse('vk/testing.php',[authData.id])).data
    // alert(JSON.stringify(testData))
    adminData = (await httpResponse('vk/userdata.php',authData.id)).data.response[0]
}
</script>

<template>
    <div v-if="authValidation.isValid">
        <nav class="navbar navbar-expand-lg bg-body-tertiary navbar-toggler">
            <div class="container-fluid">
                <RouterLink to="/" tag="div">
                    <img v-if="darkTheme" src="@/assets/img/logo_dark.png" class="navbar-brand" v-on:click="status=''" width="50">
                    <img v-else src="@/assets/img/logo_light.png" class="navbar-brand" v-on:click="status=''" width="50">
                </RouterLink>
                <!-- <img src="@/assets/img/logo.png" class="navbar-brand" v-on:click="status='main'"> -->
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="nav nav-pills me-auto mb-2 mb-lg-0">
                        <!-- <li class="nav-item" v-on:click="status='main'">
                            <RouterLink tag="p" v-on:click="status='main'" to="/" :class="`nav-link ${status == 'main' ? 'active' : ''}`">Главная</RouterLink>
                        </li> -->
                        <li class="nav-item" v-for="val in ROUTER_PILLS">
                            <RouterLink tag="p" v-if="val.perm >= authData.level" v-on:click="status=val.page" :to="`/${val.page}`" :class="`nav-link ${status == val.page ? 'active' : ''}`">{{ val.displayName }}</RouterLink>
                        </li>
                        <li class="nav-item">
                            <p class="nav-link"><br></p>
                        </li>
                    </ul>
                    <button type="button" v-if="darkTheme" :class="`btn btn-dark d-flex mx-1 my-1`" @click="themeSwitch()"><i class="bi bi-sun"></i></button>
                    <button type="button" v-else :class="`btn btn-light d-flex mx-1 my-1`" @click="themeSwitch()"><i class="bi bi-moon"></i></button>
                    <div class="dropdown">
                        <button type="button" :class="`btn btn-${darkTheme ? 'dark' : 'light'} d-flex`" data-bs-toggle="dropdown" aria-expanded="false"><img :src="adminData.photo_50" style="border-radius:50%;" width="25">&nbsp;{{shortName(`${adminData.first_name} ${adminData.last_name}`)}}</button>
                        <ul class="dropdown-menu">
                            <li><a class="dropdown-item" :href="`https://vk.com/${adminData.screen_name}`">Профиль VK</a></li>
                            <li class="dropdown-divider"></li>
                            <li><a class="dropdown-item" v-on:click="authLogout"><i class="bi bi-box-arrow-right"></i>&nbsp;Выйти</a></li>
                        </ul>
                    </div>
                    <!-- <form class="d-flex" role="idk">
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" role="switch" v-model="darkTheme" id="flexSwitchCheckDefault">
                            <label class="form-check-label" for="flexSwitchCheckDefault">{{darkTheme ? '🌙' : '☀️'}}</label>
                        </div>
                    </form> -->
                </div>
            </div>
        </nav>
        <div v-if="!ROUTER_PAGES[status] || authData.level <= ROUTER_PAGES[status].perm" class="app pt-2">
            <RouterView/>
        </div>
        <div v-else class="app pt-2">
            <ErrorPage :errorCode="403"/>
        </div>
    </div>
    <div v-else-if="invite">
        <Suspense>
            <InvitePage/>
            <template #fallback>
                <LoadingStamp/>
            </template>
        </Suspense>
    </div>
    <LoginPage v-else :errorCode="authValidation.error.code"/>
</template>

<style>
img{
    cursor: pointer;
}
.nav-link{
    cursor: pointer;
}
.form-check-input{
    cursor: pointer;
}
.nav{
    padding-top: 15px;
}

</style>