<script setup>
import { shortName, EVENT_TYPES, EVENT_RESULTS, EVENT_LEVELS, isMobile, CLASS_LET, CLASS_NUM, copyObj, getDateDMY } from './func/const.js';
import { ref, computed } from 'vue'
import * as XLSX from "xlsx";
import { getAuth, anyGrade } from './func/auth';
import { httpResponseAuth } from './func/request';

const isMobileDevice = isMobile()

const GRADES = []
for (let num of CLASS_NUM) {
    for (let lett of CLASS_LET) {
        GRADES.push(`${num}${lett}`)
    }
}

const authData = getAuth()
// authData.level = 3

const studentRecords = ref((await httpResponseAuth('records/list.php','')).data)
const studentRecordsNotChecked = computed( () => {
    let result = []
    studentRecords.value.forEach( (rec) => {
        if (rec.checked == 0 && anyGrade(rec.studentGrade)) result.push(rec)
    })
    return result
} )

// HTML Table

let eventTypeText = EVENT_TYPES.map( (val,i) => {
    return `${i+1} - ${val}`
})
function recordIdToArrayId(recordId) {
    let arrayId = undefined
    studentRecords.value.forEach((el,i) => {
        if (el.id == recordId) {
            arrayId = i
        }
    });
    return arrayId
}

const deleteRecordButtonStatus = ref({})
studentRecords.value.forEach((val)=>{
    deleteRecordButtonStatus.value[val.id] = 0
})
function deleteRecordButton(id) {
    switch (deleteRecordButtonStatus.value[id]) {
        case 0:
            deleteRecordButtonStatus.value[id]=1
            setTimeout(()=>{deleteRecordButtonStatus.value[id]=2},1000)
        break
    }
}
async function confirmDeletion(recordId) {
    deleteRecordButtonStatus.value[recordId]
    const responseResult = await httpResponseAuth('records/delete.php',recordId)
    if (responseResult.isValid) studentRecords.value.splice(recordIdToArrayId(recordId),1)
}
async function setCheckStatus(recordId,status) {
    const responseResult = await httpResponseAuth('records/status_set.php',{ id: recordId, status: status })
    if(responseResult.isValid) studentRecords.value[recordIdToArrayId(recordId)].checked = status
}

const tablePrefers = ref({
    shortEvType: true,
    filterStatus: 0,
    sortType: 0,
    hideTable: isMobile(),
    shortNames: true,
    imgPreview: true,
    lockChecked: computed( () => studentRecordsNotChecked.value.length == 0)
})
const windowView = ref({
    sel: studentRecords.value.length > 0 ? studentRecords.value[0] : { studentName: 'X Y Z', studentGrade: '9П', eventDate: '2008-04-23' },
    currentlyEdit: false,
    newEdit: studentRecords.value.length > 0 ? copyObj(studentRecords.value[0]) : { studentName: 'X Y Z', studentGrade: '9П', eventDate: '2008-04-23' },
    checkMode: false,
    checkArr: [],
    checkIndex: 0
})
//windowView.value.sel.fileName = require('@/assets/img/micropic.png')

async function editRecord() {
    const responseResult = await httpResponseAuth('records/edit.php',windowView.value.newEdit)
    if(responseResult.isValid) {
        windowView.value.sel = windowView.value.newEdit
        windowView.value.currentlyEdit = false
        studentRecords.value[recordIdToArrayId(windowView.value.sel.id)] = windowView.value.sel
    }
}

const studentRecordsTable = computed( () => {
    let preResult = []
    if (tablePrefers.value.filterStatus == 2){
        preResult = studentRecordsNotChecked.value
    } else if (tablePrefers.value.filterStatus == 1 || tablePrefers.value.filterStatus == 3 || tablePrefers.value.filterStatus == 4) {
        studentRecords.value.forEach( (rec) => {
            if (studentRecordsNotChecked.value.indexOf(rec) == -1 && (tablePrefers.value.filterStatus == 1 || (rec.checked == 1 && tablePrefers.value.filterStatus == 4) || (rec.checked == -1 && tablePrefers.value.filterStatus == 3)) ) preResult.push(rec)
        } )
    } else {
        preResult = studentRecords.value
    }
    let finalResult = []
    preResult.forEach( (rec) => {
        if (anyGrade(rec.studentGrade)) finalResult.push(rec)
    } )
    if (tablePrefers.value.sortType == 1) finalResult.sort((a, b) => a.studentName.localeCompare(b.studentName))
    else if (tablePrefers.value.sortType == 2) finalResult.sort((a, b) => GRADES.indexOf(a.studentGrade)-GRADES.indexOf(b.studentGrade))
    return finalResult
} )

// EXCEL
//                A      B      C           D       E         F          G           H       I         J   
const HEADERS = ['ID', 'ФИО', 'Класс', 'Название', 'Тип', 'Результат', 'Уровень', 'Дата', 'Файл', 'Статус']
function exportExcel() {
    const studentRecordsXLSX = copyObj(studentRecords.value)
    const TABLE_ARRAY = [HEADERS]
    studentRecordsXLSX.forEach(el => {
        TABLE_ARRAY.push( [
            el.id,el.studentName,
            el.studentGrade,
            el.eventName,
            EVENT_TYPES[el.eventType],
            EVENT_RESULTS[el.eventResult],
            EVENT_LEVELS[el.eventLevel],
            el.eventDate.split('-').reverse().join('.'),
            `https://api.kpd64.ru/uploads/${el.fileName}`,
            el.checked == 0 ? 'Не проверено' : el.checked == 1 ? 'Проверено' : 'Отклонено'
        ] )
    });
    // const worksheet = XLSX.utils.json_to_sheet(studentRecords)
    const worksheet = XLSX.utils.aoa_to_sheet(TABLE_ARRAY)

    // Установка размера колонок
    worksheet['!cols'] = [
    { wch: 4 }, // ID
    { wch: 27 }, // ФИО
    { wch: 5 }, // Класс
    { wch: 30 }, // Название
    { wch: 30 }, // Тип
    { wch: 11 }, // Результат
    { wch: 11 }, // Уровень
    { wch: 10 }, // Дата
    { wch: 7 }, // Файл
    { wch: 10 }, // Проверерно
    ];
    
    for (let i = 2; i < studentRecordsXLSX.length+2;i++) {
        worksheet[`I${i}`].l = {Target: worksheet[`I${i}`].v, Tooltip: "Нажмите для перехода по ссылке" }
        worksheet[`I${i}`].v = 'Ссылка'
    }

    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Записи учеников')
    XLSX.writeFile(workbook, `ЗАПИСИ КПД НА ${getDateDMY()}.xlsx`)
}
</script>

<template>
    <div class="p-3">
        <h1 class="text-body text-center mb-4 bt-3">Записи учеников</h1>
        <div class="card mb-3 p-2">
            <div class="card-body">
                <h4 class="mb-3">Проверка в окне</h4>
                <div class="mb-4">
                    <p v-if="tablePrefers.lockChecked">Все записи уже проверены, круто!</p>
                    <p v-else>Начните проверку в удобном оконном режиме с предпросмотром изображения и возможностью редактирования записей.</p>
                    <p v-if="anyGrade('27П')">Вам доступна проверка всех классов</p>
                    <p v-else>Вы можете проверять {{authData.grade}}</p>
                    <button
                    v-if="tablePrefers.imgPreview"
                    :disabled="tablePrefers.lockChecked"
                    data-bs-toggle="modal"
                    data-bs-target="#imgPreviewModal"
                    class="btn btn-primary mb-3"
                    @click="
                        windowView.checkMode = true;
                        windowView.checkIndex = 0
                        windowView.checkArr = studentRecordsNotChecked.map((val) => val.id )
                        if (tablePrefers.sortType == 1) windowView.checkArr.sort((a, b) => studentRecords[recordIdToArrayId(a)].studentName.localeCompare(studentRecords[recordIdToArrayId(b)].studentName))
                        else if (tablePrefers.sortType == 2) windowView.checkArr.sort((a, b) => GRADES.indexOf(studentRecords[recordIdToArrayId(a)].studentGrade)-GRADES.indexOf(studentRecords[recordIdToArrayId(b)].studentGrade))
                        windowView.sel=studentRecords[recordIdToArrayId(windowView.checkArr[0])];
                        windowView.currentlyEdit=false;
                        windowView.newEdit=copyObj( windowView.sel );
                    "><i class="bi bi-layout-text-window"></i> Начать проверку</button>
                    <div><label>Сортировать по:</label></div>
                    <div class="btn-group">
                        <button :class="`btn btn${tablePrefers.sortType == 0 ? '' : '-outline'}-primary`" @click="tablePrefers.sortType = 0"><i class="bi bi-clock-history"></i> По хронологии</button>
                        <button :class="`btn btn${tablePrefers.sortType == 1 ? '' : '-outline'}-primary`" @click="tablePrefers.sortType = 1"><i class="bi bi-sort-alpha-down"></i> По ученикам</button>
                        <button v-if="anyGrade('27П')" :class="`btn btn${tablePrefers.sortType == 2 ? '' : '-outline'}-primary`" @click="tablePrefers.sortType = 2"><i class="bi bi-sort-numeric-down"></i> По классам</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card mb-3 p-2" v-if="authData.level < 3">
            <div class="card-body">
                <h4 class="mb-3">Действия</h4>
                <div class="mb-4">
                    <button type="button" class="btn btn-danger mx-1 my-1" disabled><i class="bi bi-trash3"></i> Очистить все записи</button>
                    <button type="button" class="btn btn-info mx-1 my-1" disabled><i class="bi bi-snow"></i> Запретить заполнение</button>
                    <div class="form-text" for="xddccc">Очищение записей и закрытие формы доступно в разделе <RouterLink to="/settings">настроек</RouterLink>.</div>
                </div>
                <div>
                    <!-- <button data-bs-toggle="modal" data-bs-target="#imgPreviewModal" class="btn btn-info mx-1 my-1" @click="imgPreview.sel=studentRecords[0];imgPreview.currentlyEdit=false;imgPreview.newEdit=copyObj( studentRecords[0] )"><i class="bi bi-layout-text-window"></i> Тестовое окно</button> -->
                    <button type="button" class="btn btn-success mx-1 my-1" @click="exportExcel"><i class="bi bi-download"></i> Сохранить как таблицу Excel</button>
                    <!-- <button type="button" class="btn btn-info mx-2 my-1" @click="importButtonToggled=!importButtonToggled" v-if="!importButtonToggled"><i class="bi bi-upload"></i> Импортировать из таблицы</button>
                    <button type="button" class="btn btn-secondary mx-2 my-1" @click="importButtonToggled=!importButtonToggled" v-else>Отменить импорт</button> -->
                </div>
            </div>
        </div>
        <!--  v-if="!tablePrefers.hideTable" -->
        <div class="maincontent">
            <div class="card mb-3 p-2">
                <div class="card-body">
                    <h4 class="mb-3">Сортировка таблицы</h4>
                    <label>Фильтр по статусу:</label>
                    <select v-model="tablePrefers.filterStatus" class="form-select mb-3">
                        <option :value="0">Все записи</option>
                        <option :value="1">Только проверенные</option>
                        <option :value="2">Только непроверенные</option>
                        <option :value="3">Только отклонённые</option>
                        <option :value="4">Только принятые</option>
                    </select>
                    <div><label>Сортировать по:</label></div>
                    <div class="mb-3 btn-group">
                        <button :class="`btn btn${tablePrefers.sortType == 0 ? '' : '-outline'}-primary`" @click="tablePrefers.sortType = 0"><i class="bi bi-clock-history"></i> По хронологии</button>
                        <button :class="`btn btn${tablePrefers.sortType == 1 ? '' : '-outline'}-primary`" @click="tablePrefers.sortType = 1"><i class="bi bi-sort-alpha-down"></i> По ученикам</button>
                        <button v-if="anyGrade('27П')" :class="`btn btn${tablePrefers.sortType == 2 ? '' : '-outline'}-primary`" @click="tablePrefers.sortType = 2"><i class="bi bi-sort-numeric-down"></i> По классам</button>
                    </div>
                    <h4 class="mb-3">Вид таблицы</h4>
                    <div class="form-check form-switch mb-3">
                        <input class="form-check-input" type="checkbox" role="switch" id="imgPreview" v-model="tablePrefers.imgPreview">
                        <label class="form-check-label text-body" for="imgPreview">Окно предпросмотра изображения, а не переход по ссылке</label>
                        <div class="form-text" for="">Для того чтобы увидеть окно предпросмотра изображения, нажмите на кнопку <i class="bi bi-layout-text-window"></i></div>
                    </div>
                    <div class="form-check form-switch mb-3">
                        <input class="form-check-input" type="checkbox" role="switch" id="shortNames" v-model="tablePrefers.shortNames">
                        <label class="form-check-label text-body" for="shortNames">Сократить ФИО до инициалов</label>
                        <!-- <div v-if="studentRecords.length >= 1" class="form-text" for="">Сократит "{{ studentRecords[0].studentName }}" в "{{ shortName(studentRecords[0].studentName) }}"</div> -->
                    </div>
                    <div class="form-check form-switch mb-3">
                        <input class="form-check-input" type="checkbox" role="switch" id="shortEventType" v-model="tablePrefers.shortEvType">
                        <label class="form-check-label text-body" for="shortEventType">Сократить имя типа мероприятия</label>
                        <div class="form-text" for="">Введёт цифровые обозначения для названия мероприятий</div>
                    </div>
                    <div class="accordion" id="accordionExample" v-if="tablePrefers.shortEvType">
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                Показать обозначения типов
                            </button>
                            </h2>
                            <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <p v-for="ev in eventTypeText" :key="ev">{{ ev }}</p>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card p-3 mb-3">
                <div class="card-body">
                    <p>Показано записей: {{ studentRecordsTable.length }} (Всего в системе: {{ studentRecords.length }})</p>
                    <table class="table">
                        <thead>
                            <tr>
                            <th scope="col">ID</th>
                            <th scope="col">ФИО</th>
                            <th scope="col">Класс</th>
                            <th scope="col">Мероприятие</th>
                            <th scope="col">Тип</th>
                            <th scope="col">Результат</th>
                            <th scope="col">Уровень</th>
                            <th scope="col">Дата</th>
                            <th scope="col">Действия</th>
                            <th scope="col">Статус</th>
                            <!-- <th scope="col">Действие</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(rec,i) in studentRecordsTable" :key="i">
                                <td>{{ rec.id }}</td>
                                <td>{{tablePrefers.shortNames ? shortName(rec.studentName) : rec.studentName }}</td>
                                <td>{{ rec.studentGrade }}</td>
                                <td>{{ rec.eventName }}</td>
                                <td v-if="tablePrefers.shortEvType"><abbr :title="EVENT_TYPES[rec.eventType]">{{ rec.eventType+1 }}</abbr></td>
                                <td v-else>{{ EVENT_TYPES[rec.eventType] }}</td>
                                <td>{{ EVENT_RESULTS[rec.eventResult] }}</td>
                                <td>{{ EVENT_LEVELS[rec.eventLevel] }}</td>
                                <td>{{ rec.eventDate.split('-').reverse().join('.') }}</td>
                                <td v-if="isMobileDevice">
                                    <button v-if="tablePrefers.imgPreview" data-bs-toggle="modal" data-bs-target="#imgPreviewModal" class="btn btn-info mx-1 my-1" @click="windowView.sel=studentRecords[recordIdToArrayId(rec.id)];windowView.currentlyEdit=false;windowView.newEdit=copyObj( studentRecords[recordIdToArrayId(rec.id)] )"><i class="bi bi-layout-text-window"></i></button>
                                    <a v-else :href="`https://api.kpd64.ru/uploads/${rec.fileName}`" target="_blank" class="btn btn-info mx-1 my-1"><i class="bi bi-box-arrow-up-right"></i></a>
                                    <button v-if="deleteRecordButtonStatus[rec.id]==0" @click="deleteRecordButton(rec.id)" class="btn btn-secondary mx-1 my-1"><i class="bi bi-trash3"></i></button>
                                    <button v-else-if="deleteRecordButtonStatus[rec.id]==1" class="btn btn-danger mx-1 my-1" type="button" disabled><span class="spinner-border spinner-border-sm" aria-hidden="true"></span><span class="visually-hidden" role="status">Loading...</span></button>
                                    <div v-else-if="deleteRecordButtonStatus[rec.id]==2"><button class="btn btn-danger mx-1 my-1" type="button" @click="confirmDeletion(rec.id)">Подтвердить</button><button class="btn btn-secondary mx-1 my-1" @click="deleteRecordButtonStatus[rec.id]=0" type="button">Отмена</button></div>
                                </td>
                                <td v-else>
                                    <button v-if="tablePrefers.imgPreview" data-bs-toggle="modal" data-bs-target="#imgPreviewModal" class="btn btn-outline-info mx-1 my-1" @click="windowView.sel=studentRecords[recordIdToArrayId(rec.id)];windowView.currentlyEdit=false;windowView.newEdit=copyObj( studentRecords[recordIdToArrayId(rec.id)] )"><i class="bi bi-layout-text-window"></i></button>
                                    <a v-else :href="`https://api.kpd64.ru/uploads/${rec.fileName}`" target="_blank" class="btn btn-info mx-1 my-1"><i class="bi bi-box-arrow-up-right"></i></a>
                                    <button data-bs-toggle="modal" data-bs-target="#imgPreviewModal" class="btn btn-warning mx-1 my-1" @click="windowView.sel=studentRecords[recordIdToArrayId(rec.id)];windowView.currentlyEdit=true;windowView.newEdit=copyObj( studentRecords[recordIdToArrayId(rec.id)] )"><i class="bi bi-pencil"></i></button>
                                    <button v-if="deleteRecordButtonStatus[rec.id]==0" @click="deleteRecordButton(rec.id)" class="btn btn-secondary mx-1 my-1"><i class="bi bi-trash3"></i></button>
                                    <button v-else-if="deleteRecordButtonStatus[rec.id]==1" class="btn btn-danger mx-1 my-1" type="button" disabled><span class="spinner-border spinner-border-sm" aria-hidden="true"></span><span class="visually-hidden" role="status">Loading...</span></button>
                                    <div v-else-if="deleteRecordButtonStatus[rec.id]==2"><button class="btn btn-danger mx-1 my-1" type="button" @click="confirmDeletion(rec.id)">Подтвердить</button><button class="btn btn-secondary mx-1 my-1" @click="deleteRecordButtonStatus[rec.id]=0" type="button">Отмена</button></div>
                                </td>
                                <td>
                                    <div class="btn-group">
                                        <button :class="`btn btn-${rec.checked != 1 ? 'outline-': ''}success`" @click="setCheckStatus(rec.id,1)"><i class="bi bi-check-circle"></i></button>
                                        <button :class="`btn btn-${rec.checked != -1 ? 'outline-': ''}danger`" @click="setCheckStatus(rec.id,-1)"><i class="bi bi-x-circle"></i></button>
                                        <button :class="`btn btn-${rec.checked != 0 ? 'outline-': ''}secondary`" @click="setCheckStatus(rec.id,0)"><i class="bi bi-question-circle"></i></button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>  
        <!-- Modal -->
        <div class="modal fade" id="imgPreviewModal" tabindex="-1" aria-labelledby="imgPreviewModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-3 text-body" id="imgPreviewModalLabel">{{ windowView.sel.studentName }} из {{ windowView.sel.studentGrade }}</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-footer">
                    <div class="btn-group mx-1 my-1" v-if="windowView.checkMode && studentRecords[recordIdToArrayId(windowView.checkArr[windowView.checkIndex+1])]">
                        <button
                            :class="`btn btn-${windowView.sel.checked != 1 ? 'outline-': ''}success`"
                            data-bs-toggle="modal"
                            data-bs-target="#imgPreviewModal2"
                            @click="
                            setCheckStatus(windowView.sel.id,1);
                            windowView.checkIndex++
                            if (studentRecords[recordIdToArrayId(windowView.checkArr[windowView.checkIndex])]) {
                                windowView.sel=studentRecords[recordIdToArrayId(windowView.checkArr[windowView.checkIndex])]
                                windowView.newEdit=windowView.sel
                            }
                            ">
                                <i class="bi bi-check-circle"></i>
                        </button>
                        <button
                            :class="`btn btn-${windowView.sel.checked != -1 ? 'outline-': ''}danger`"
                            data-bs-toggle="modal"
                            data-bs-target="#imgPreviewModal2"
                            @click="
                            setCheckStatus(windowView.sel.id,-1);
                            windowView.checkIndex++
                            if (studentRecords[recordIdToArrayId(windowView.checkArr[windowView.checkIndex])]) {
                                windowView.sel=studentRecords[recordIdToArrayId(windowView.checkArr[windowView.checkIndex])]
                                windowView.newEdit=windowView.sel
                            }
                            ">
                                <i class="bi bi-x-circle"></i>
                        </button>
                        <button
                            :class="`btn btn-${windowView.sel.checked != 0 ? 'outline-': ''}secondary`"
                            data-bs-toggle="modal"
                            data-bs-target="#imgPreviewModal2"
                            @click="
                            setCheckStatus(windowView.sel.id,0);
                            windowView.checkIndex++
                            if (studentRecords[recordIdToArrayId(windowView.checkArr[windowView.checkIndex])]) {
                                windowView.sel=studentRecords[recordIdToArrayId(windowView.checkArr[windowView.checkIndex])]
                                windowView.newEdit=windowView.sel
                            }
                            ">
                                <i class="bi bi-question-circle"></i>
                        </button>
                    </div>
                    <div class="btn-group mx-1 my-1" v-else>
                        <button
                            :class="`btn btn-${windowView.sel.checked != 1 ? 'outline-': ''}success`"
                            data-bs-dismiss="modal"
                            @click="
                            setCheckStatus(windowView.sel.id,1)
                            ">
                                <i class="bi bi-check-circle"></i>
                        </button>
                        <button
                            :class="`btn btn-${windowView.sel.checked != -1 ? 'outline-': ''}danger`"
                            data-bs-dismiss="modal"
                            @click="
                            setCheckStatus(windowView.sel.id,-1)
                            ">
                                <i class="bi bi-x-circle"></i>
                        </button>
                        <button
                            :class="`btn btn-${windowView.sel.checked != 0 ? 'outline-': ''}secondary`"
                            data-bs-dismiss="modal"
                            @click="
                            setCheckStatus(windowView.sel.id,0)
                            ">
                                <i class="bi bi-question-circle"></i>
                        </button>
                    </div>
                    <button type="button" class="btn btn-warning" @click="()=>{if (!windowView.currentlyEdit) { windowView.currentlyEdit = true } else { editRecord() }}"><i class="bi bi-pencil" v-if="!windowView.currentlyEdit"></i><i class="bi bi-floppy" v-else></i></button>
                    <!-- <button type="button" :disabled="imgPreview.currentlyEdit" class="btn btn-secondary" data-bs-dismiss="modal"><i class="bi bi-trash"></i></button> -->
                </div>
                <div class="modal-body">
                        <div class="card mb-3">
                            <div class="card-body">
                                <p class="text-body" v-if="!windowView.currentlyEdit">
                                    Название: <b>{{ windowView.sel.eventName }}</b><br>
                                    Тип: <b>{{ EVENT_TYPES[windowView.sel.eventType] }}</b><br>
                                    Уровень: <b>{{ EVENT_LEVELS[windowView.sel.eventLevel] }}</b><br>
                                    Дата: <b>{{windowView.sel.eventDate.split('-').reverse().join('.')}}</b><br>
                                    Итог: <b>{{ EVENT_RESULTS[windowView.sel.eventResult] }}</b><br>
                                
                                </p>
                                <div v-else>
                                    <button class="btn btn-secondary mb-2" @click="windowView.currentlyEdit=false;windowView.newEdit=copyObj(windowView.sel)"><i class="bi bi-backspace"></i> Отменить редактирование</button>
                                    <div>
                                        <label class="form-label">Название</label>
                                        <input type="text" class="form-control" v-model="windowView.newEdit.eventName" placeholder="Соревнование по поеданию сырников">
                                        <label class="form-label">Дата</label>
                                        <input type="date" class="form-control" v-model="windowView.newEdit.eventDate" placeholder="Соревнование по поеданию сырников">
                                        <label class="form-label">Тип мероприятия</label>
                                        <div class="mb-2">
                                            <select v-model="windowView.newEdit.eventType" class="form-select">
                                                <option value="0">Всероссийская олимпиада школьников (учащиеся)</option>
                                                <option value="1">Научно-практические конференции (учащиеся)</option>
                                                <option value="2">Заочные и дистанционные конкурсные мероприятия (учащиеся)</option>
                                                <option value="3">Очные интеллектуальные конкурсные мероприятия (учащиеся)</option>
                                                <option value="4">Мероприятия и конкурсы художественно-эстетической направленности (учащиеся)</option>
                                                <option value="5">Спортивные состязания (учащиеся)</option>
                                                <option value="6">Социально-значимые мероприятия</option>
                                                <option value="7">Военно-патриотические, экологические, туристско-краеведческие, поисковые мероприятия (учащиеся)</option>
                                            </select>
                                        </div>
                                        <div>
                                        <label>Результат:</label>
                                            <div class="form-check"><input class="form-check-input" type="radio" id="winner" :value="0" v-model="windowView.newEdit.eventResult" name="eventResult"><label class="form-check-label" for="winner">Победитель</label></div>
                                            <div class="form-check"><input class="form-check-input" type="radio" id="prize" :value="1" v-model="windowView.newEdit.eventResult" name="eventResult"><label class="form-check-label" for="prize">Призёр</label></div>
                                            <div class="form-check"><input class="form-check-input" type="radio" id="member" :value="2" v-model="windowView.newEdit.eventResult" name="eventResult"><label class="form-check-label" for="member">Участник</label></div>
                                            <div class="form-check"><input class="form-check-input" type="radio" id="organizer" :value="3" v-model="windowView.newEdit.eventResult" name="eventResult"><label class="form-check-label" for="organizer">Организатор</label></div>
                                        </div>
                                        <label>Уровень:</label>
                                        <div>
                                            <div class="form-check"><input class="form-check-input" type="radio" id="school" :value="0" v-model="windowView.newEdit.eventLevel" name="eventLevel"><label class="form-check-label" for="school">Школьный</label></div>
                                            <div class="form-check"><input class="form-check-input" type="radio" id="city" :value="1" v-model="windowView.newEdit.eventLevel" name="eventLevel"><label class="form-check-label" for="city">Муниципальный</label></div>
                                            <div class="form-check"><input class="form-check-input" type="radio" id="region" :value="2" v-model="windowView.newEdit.eventLevel" name="eventLevel"><label class="form-check-label" for="region">Региональный</label></div>
                                            <div class="form-check"><input class="form-check-input" type="radio" id="country" :value="3" v-model="windowView.newEdit.eventLevel" name="eventLevel"><label class="form-check-label" for="country">Всероссийский</label></div>
                                            <div class="form-check"><input class="form-check-input" type="radio" id="world" :value="4" v-model="windowView.newEdit.eventLevel" name="eventLevel"><label class="form-check-label" for="world">Международный</label></div>
                                        </div>
                                        <label >Класс:</label>
                                        <select class="form-select" v-model="windowView.newEdit.studentGrade">
                                            <option v-for="grade in GRADES" :key="grade" :value="grade">{{grade}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                    </div>
                    <img class="img-thumbnail" :src="`https://api.kpd64.ru/uploads/${windowView.sel.fileName}`">
                </div>
            </div>
            </div>
        </div>
        <!-- Modal -->
        <div class="modal fade" id="imgPreviewModal2" tabindex="-1" aria-labelledby="imgPreviewModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-3 text-body" id="imgPreviewModalLabel">{{ windowView.sel.studentName }} из {{ windowView.sel.studentGrade }}</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-footer">
                    <div class="btn-group mx-1 my-1" v-if="windowView.checkMode">
                        <button
                            :class="`btn btn-${windowView.sel.checked != 1 ? 'outline-': ''}success`"
                            data-bs-dismiss="modal"
                            data-bs-toggle="modal"
                            data-bs-target="#imgPreviewModal"
                            @click="
                            setCheckStatus(windowView.sel.id,1);
                            windowView.checkIndex++
                            if (studentRecords[recordIdToArrayId(windowView.checkArr[windowView.checkIndex])]) {
                                windowView.sel=studentRecords[recordIdToArrayId(windowView.checkArr[windowView.checkIndex])]
                                windowView.newEdit=windowView.sel
                            }
                            ">
                                <i class="bi bi-check-circle"></i>
                        </button>
                        <button
                            :class="`btn btn-${windowView.sel.checked != -1 ? 'outline-': ''}danger`"
                            data-bs-toggle="modal"
                            data-bs-target="#imgPreviewModal"
                            @click="
                            setCheckStatus(windowView.sel.id,-1);
                            windowView.checkIndex++
                            if (studentRecords[recordIdToArrayId(windowView.checkArr[windowView.checkIndex])]) {
                                windowView.sel=studentRecords[recordIdToArrayId(windowView.checkArr[windowView.checkIndex])]
                                windowView.newEdit=windowView.sel
                            }
                            ">
                                <i class="bi bi-x-circle"></i>
                        </button>
                        <button
                            :class="`btn btn-${windowView.sel.checked != 0 ? 'outline-': ''}secondary`"
                            data-bs-toggle="modal"
                            data-bs-target="#imgPreviewModal"
                            @click="
                            setCheckStatus(windowView.sel.id,0);
                            windowView.checkIndex++
                            if (studentRecords[recordIdToArrayId(windowView.checkArr[windowView.checkIndex])]) {
                                windowView.sel=studentRecords[recordIdToArrayId(windowView.checkArr[windowView.checkIndex])]
                                windowView.newEdit=windowView.sel
                            }
                            ">
                                <i class="bi bi-question-circle"></i>
                        </button>
                    </div>
                    <div class="btn-group mx-1 my-1" v-else>
                        <button
                            :class="`btn btn-${windowView.sel.checked != 1 ? 'outline-': ''}success`"
                            data-bs-dismiss="modal"
                            @click="
                            setCheckStatus(windowView.sel.id,1)
                            ">
                                <i class="bi bi-check-circle"></i>
                        </button>
                        <button
                            :class="`btn btn-${windowView.sel.checked != -1 ? 'outline-': ''}danger`"
                            data-bs-dismiss="modal"
                            @click="
                            setCheckStatus(windowView.sel.id,-1)
                            ">
                                <i class="bi bi-x-circle"></i>
                        </button>
                        <button
                            :class="`btn btn-${windowView.sel.checked != 0 ? 'outline-': ''}secondary`"
                            data-bs-dismiss="modal"
                            @click="
                            setCheckStatus(windowView.sel.id,0)
                            ">
                                <i class="bi bi-question-circle"></i>
                        </button>
                    </div>
                    <button type="button" class="btn btn-warning" @click="()=>{if (!windowView.currentlyEdit) { windowView.currentlyEdit = true } else { editRecord() }}"><i class="bi bi-pencil" v-if="!windowView.currentlyEdit"></i><i class="bi bi-floppy" v-else></i></button>
                    <!-- <button type="button" :disabled="imgPreview.currentlyEdit" class="btn btn-secondary" data-bs-dismiss="modal"><i class="bi bi-trash"></i></button> -->
                </div>
                <div class="modal-body">
                        <div class="card mb-3">
                            <div class="card-body">
                                <p class="text-body" v-if="!windowView.currentlyEdit">
                                    Название: <b>{{ windowView.sel.eventName }}</b><br>
                                    Тип: <b>{{ EVENT_TYPES[windowView.sel.eventType] }}</b><br>
                                    Дата: <b>{{windowView.sel.eventDate.split('-').reverse().join('.')}}</b><br>
                                    Итог: <b>{{ EVENT_RESULTS[windowView.sel.eventResult] }}</b><br>
                                </p>
                                <div v-else>
                                    <button class="btn btn-secondary mb-2" @click="windowView.currentlyEdit=false;windowView.newEdit=copyObj(windowView.sel)"><i class="bi bi-backspace"></i> Отменить редактирование</button>
                                    <div>
                                        <label class="form-label">Название</label>
                                        <input type="text" class="form-control" v-model="windowView.newEdit.eventName" placeholder="Соревнование по поеданию сырников">
                                        <label class="form-label">Дата</label>
                                        <input type="date" class="form-control" v-model="windowView.newEdit.eventDate" placeholder="Соревнование по поеданию сырников">
                                        <label class="form-label">Тип мероприятия</label>
                                        <div class="mb-2">
                                            <select v-model="windowView.newEdit.eventType" class="form-select">
                                                <option value="0">Всероссийская олимпиада школьников (учащиеся)</option>
                                                <option value="1">Научно-практические конференции (учащиеся)</option>
                                                <option value="2">Заочные и дистанционные конкурсные мероприятия (учащиеся)</option>
                                                <option value="3">Очные интеллектуальные конкурсные мероприятия (учащиеся)</option>
                                                <option value="4">Мероприятия и конкурсы художественно-эстетической направленности (учащиеся)</option>
                                                <option value="5">Спортивные состязания (учащиеся)</option>
                                                <option value="6">Социально-значимые мероприятия</option>
                                                <option value="7">Военно-патриотические, экологические, туристско-краеведческие, поисковые мероприятия (учащиеся)</option>
                                            </select>
                                        </div>
                                        <div>
                                        <label>Результат:</label>
                                            <div class="form-check"><input class="form-check-input" type="radio" id="winner" :value="0" v-model="windowView.newEdit.eventResult" name="eventResult"><label class="form-check-label" for="winner">Победитель</label></div>
                                            <div class="form-check"><input class="form-check-input" type="radio" id="prize" :value="1" v-model="windowView.newEdit.eventResult" name="eventResult"><label class="form-check-label" for="prize">Призёр</label></div>
                                            <div class="form-check"><input class="form-check-input" type="radio" id="member" :value="2" v-model="windowView.newEdit.eventResult" name="eventResult"><label class="form-check-label" for="member">Участник</label></div>
                                            <div class="form-check"><input class="form-check-input" type="radio" id="organizer" :value="3" v-model="windowView.newEdit.eventResult" name="eventResult"><label class="form-check-label" for="organizer">Организатор</label></div>
                                        </div>
                                        <label>Уровень:</label>
                                        <div>
                                            <div class="form-check"><input class="form-check-input" type="radio" id="school" :value="0" v-model="windowView.newEdit.eventLevel" name="eventLevel"><label class="form-check-label" for="school">Школьный</label></div>
                                            <div class="form-check"><input class="form-check-input" type="radio" id="city" :value="1" v-model="windowView.newEdit.eventLevel" name="eventLevel"><label class="form-check-label" for="city">Муниципальный</label></div>
                                            <div class="form-check"><input class="form-check-input" type="radio" id="region" :value="2" v-model="windowView.newEdit.eventLevel" name="eventLevel"><label class="form-check-label" for="region">Региональный</label></div>
                                            <div class="form-check"><input class="form-check-input" type="radio" id="country" :value="3" v-model="windowView.newEdit.eventLevel" name="eventLevel"><label class="form-check-label" for="country">Всероссийский</label></div>
                                            <div class="form-check"><input class="form-check-input" type="radio" id="world" :value="4" v-model="windowView.newEdit.eventLevel" name="eventLevel"><label class="form-check-label" for="world">Международный</label></div>
                                        </div>
                                        <label >Класс:</label>
                                        <select class="form-select" v-model="windowView.newEdit.studentGrade">
                                            <option v-for="grade in GRADES" :key="grade" :value="grade">{{grade}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                    </div>
                    <img class="img-thumbnail" :src="`https://api.kpd64.ru/uploads/${windowView.sel.fileName}`">
                </div>
            </div>
            </div>
        </div>
    </div>
</template>

<style>
/* Для мобильных устройств */
@media only screen and (max-width: 600px) {
  table {
    width: 100%;
    overflow-x: auto;
    display: block;
  }
  th, td {
    min-width: 100px;
  }
}
/* .hover_img a { position:relative; }
.hover_img a span { position:absolute; display:none; z-index:99; }
.hover_img a:hover span { display:block; } */
</style>