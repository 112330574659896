<script setup>
import { ref } from 'vue'
import { httpResponseAuth, httpGet } from '../func/request';
import { convertBytes } from '../func/const';
import { getAuth } from '../func/auth';

const authData = getAuth()

// Красивое подтверждение удаления записей
const removeButtonStatus = ref(0)
const removeCheckbox = ref(false)
const fileCheckbox = ref(true)
const fileData = convertBytes((await httpGet('storage/status.php')).data)

// Показывать алерт удаления записей
const showSuccessDBAlert = ref('')

// При нажатие кнопки очищения записей (но не подтверждение)
function tryRemoveAllData() {
    removeButtonStatus.value=1
    setTimeout( () => {
        removeButtonStatus.value=2
    },1500 )
}

// 
async function removeAllData() {
    if (fileCheckbox.value) {
        const responseResult = await httpResponseAuth('storage/clear.php')
        if (!responseResult.isValid) return
    }
    const responseData = await httpResponseAuth('records/clearall.php')
    showSuccessDBAlert.value = responseData.isValid
    if(responseData.isValid) removeButtonStatus.value = 0
}
</script>

<template>
    <div class="alert alert-success alert-dismissible fade show" role="alert" v-if="showSuccessDBAlert === true">
            <i class="bi bi-check-circle"></i> Все записи учеников успешно удалены!
            <button type="button" class="btn-close" @click="showSuccessDBAlert=false"></button>
    </div>
    <div class="alert alert-danger alert-dismissible fade show" role="alert" v-else-if="showSuccessDBAlert === false">
            <i class="bi bi-exclamation-circle"></i> Не удалось удалить записи учеников
            <button type="button" class="btn-close" @click="showSuccessDBAlert=''"></button>
    </div>
    <div>
        <button class="btn btn-danger" v-if="removeButtonStatus==0" @click="tryRemoveAllData" :disabled="authData.level>=2"><i class="bi bi-trash3"></i> Очистить все записи</button>
        <button class="btn btn-danger" v-else-if="removeButtonStatus==1" type="button" disabled><span class="spinner-border spinner-border-sm" aria-hidden="true"></span><span role="status"> Очистить все записи</span></button>
        <div v-else-if="removeButtonStatus==2">
            <p class="text-body">Будут удалены все записи учеников. Это действие невозможно будет отменить. Продолжить?</p>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="filedelCheckbox" v-model="fileCheckbox">
                <label class="form-check-label" for="filedelCheckbox">Также удалить файлы, которые были прикреплены к заявке ({{ fileData }})</label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="confirmCheckbox" v-model="removeCheckbox">
                <label class="form-check-label" for="confirmCheckbox">Я осознаю, что это дейтсвие невозможно будет отменить.</label>
            </div>
            <button class="btn btn-danger mx-2 my-1" type="button" :disabled="!removeCheckbox" @click="removeAllData">Удалить</button>
            <button class="btn btn-secondary" type="button" @click="removeButtonStatus=0">Отмена</button>
        </div>
        <div class="form-text" v-if="removeButtonStatus!=2">Будут удалены все записи учеников. Перед выполнением, убедитесь, что вы эскпортировали все нужные данные.</div>
    </div>
</template>