<script setup>
import { ref } from 'vue'
import { convertBytes } from '../func/const';
import { httpGet, httpResponseAuth } from '../func/request';
import { getAuth } from '../func/auth';

const authData = getAuth()

// Storage
const removeFileCheckbox = ref(false)
const folderSizeView = ref('')
const folderSize = ref(0)
const storageSize = ref(90*1024*1024*1024)
const storageSizeView = ref(convertBytes(storageSize.value))

// Показывать алекрт удаления файлов
const showSuccessFileAlert = ref('')

const removeFileButtonStatus = ref(0)

folderSize.value = (await httpGet('storage/status.php')).data
folderSizeView.value = convertBytes(folderSize.value)

function tryRemoveFiles() {
    removeFileButtonStatus.value=1
    setTimeout( () => {
        removeFileButtonStatus.value=2
    },1500 )
}

const storageStatus = ref(Math.round(folderSize.value/storageSize.value*100))
if (storageStatus.value == 0 && folderSize.value > 0) storageStatus.value = 0.5

async function removeFiles() {
    const responseResult = await httpResponseAuth('storage/clear.php')
    showSuccessFileAlert.value = responseResult.isValid
    if (responseResult.isValid) {
        folderSize.value = 0
        folderSizeView.value = convertBytes(folderSize.value)
        storageStatus.value = 0
        removeFileButtonStatus.value = 0
    }
}
</script>

<template>
    <div class="alert alert-success alert-dismissible fade show" role="alert" v-if="showSuccessFileAlert === true">
        <i class="bi bi-check-circle"></i> Все файлы учеников успешно удалены!
        <button type="button" class="btn-close" @click="showSuccessFileAlert=''"></button>
    </div>
    <div class="alert alert-danger alert-dismissible fade show" role="alert" v-else-if="showSuccessFileAlert === false">
        <i class="bi bi-exclamation-circle"></i> Не удалось удалить файлы учеников
        <button type="button" class="btn-close" @click="showSuccessFileAlert=''"></button>
    </div>
    <div class="progress mb-3" role="progressbar" aria-label="Basic example" :aria-valuenow="storageStatus" aria-valuemin="0" aria-valuemax="100">
        <div :class="`progress-bar${removeFileButtonStatus > 1 ? ' bg-danger progress-bar-striped progress-bar-animated': ''}`" :style="`width: ${storageStatus}%`"></div>
    </div>
    <div class="mb-4">
        <p class="text-body">Занято {{ folderSizeView }} из {{ storageSizeView }}</p>
    </div>
    <div class="mb-3" v-if="authData.level<2">
        <a class="btn btn-success" href="https://api.kpd64.ru/uploads" target="_blank"><i class="bi bi-folder-symlink"></i> Перейти к директории</a>
        <div class="form-text">Доступ только для чтения</div>
    </div>
    <div class="mb-3" v-if="authData.level<2">
        <button class="btn btn-danger" v-if="removeFileButtonStatus==0" @click="tryRemoveFiles" :disabled="folderSize == 0"><i class="bi bi-folder-minus"></i> Очистить хранилище</button>
        <button class="btn btn-danger" v-else-if="removeFileButtonStatus==1" type="button" disabled><span class="spinner-border spinner-border-sm" aria-hidden="true"></span><span role="status"> Очистить хранилище</span></button>
        <div v-else-if="removeFileButtonStatus==2">
            <p class="text-body">Будут удалены все файлы учеников на {{ folderSizeView }}. Это действие невозможно будет отменить. Продолжить?</p>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="confirmFileCheckbox" v-model="removeFileCheckbox">
                <label class="form-check-label" for="confirmFileCheckbox">Я осознаю, что это дейтсвие невозможно будет отменить.</label>
            </div>
            <button class="btn btn-danger mx-2 my-1" type="button" :disabled="!removeFileCheckbox" @click="removeFiles">Удалить {{ folderSizeView }}</button>
            <button class="btn btn-secondary" type="button" @click="removeFileButtonStatus=0">Отмена</button>
        </div>
        <div class="form-text" v-if="removeFileButtonStatus!=2">Будут удалены все файлы, которые прикрепили ученики. Перед выполнением, убедитесь, что вы уже проверили записи учеников. Очистив хранилище, файлы подтверждения (грамоты, дипломы) будут недоступны!</div>
    </div>
    <!-- <div>
        <button class="btn btn-danger" type="button" disabled><i class="bi bi-folder-check"></i> Удалить проверенные файлы</button>
        <p class="form-text" for="">Файлы, которые были прикреплены к проверенным записям будут удалены.</p>
    </div> -->
</template>