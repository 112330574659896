<script setup>
/* eslint-disable */
import { ref, defineProps } from 'vue'
import { getDomain } from './func/routers'
const uuid = '7da72dc06ae54bc984021d2a75152498';
const appId = 51823305;
const redirectUri = `https://api.kpd64.ru/auth/vk${getDomain().split(':')[0] == 'localhost' ? '_local': ''}.php`; // Адрес для перехода после авторизации, который совпадает с доверенным редиректом из настроек приложения.
const query = ref(`uuid=${uuid}&app_id=${appId}&response_type=silent_token&redirect_uri=${redirectUri}`)

const { errorCode } = defineProps({
    errorCode: Number
})
const alertMessage = ref()
if (errorCode == 498) {
    alertMessage.value = 'Ваша сессия устарела, необходимо авторизироваться заново!'
} else if (errorCode == 403) {
    alertMessage.value = 'Ваш аккаунт VK не добавлен в панель. Обратитесь к администратору.'
} else if (errorCode == 423) {
    alertMessage.value = 'Доступ к панели для проверяющих закрыт администраторам.'
} else if (errorCode == 401) {
    alertMessage.value = ''
} else {
    alertMessage.value = 'Ошибка '+errorCode
}

const inputPassword = ref('')
const inputLogin = ref('')
const showWrongInputALert = ref(false)
const showSuccessInputALert = ref(false)

function loginButton() {
    if (inputLogin.value == 'student' && inputPassword.value == 'Qwerty123') {
        showSuccessInputALert.value = true
        setTimeout(() => { window.location.href = 'https://api.kpd64.ru/' }, 3000)

    }
    showWrongInputALert.value = true
}

</script>

<template>
    <div class="modal modal-sheet position-static d-block p-4 py-md-5" tabindex="-1">

        <div class="modal-dialog">
            <div class="modal-content rounded-4 shadow">
                <div class="modal-header p-5 pb-4 border-bottom-0">
                    <h1 class="fw-bold mb-0 fs-2 text-body">Авторизация в панели</h1>
                </div>
                <div class="modal-body p-5 pt-0">
                    <div class="alert alert-success" role="alert" v-if="showSuccessInputALert">
                        Пароль и логин совпали! Перенаправляем на панель администратора...
                    </div>
                    <div class="alert alert-danger" role="alert" v-else-if="showWrongInputALert">
                        Неверный логин или пароль.
                    </div>
                    <div class="alert alert-warning" role="alert" v-else-if="alertMessage.length > 0">
                        {{ alertMessage }}
                    </div>
                    <form class="" @submit.prevent>
                        <div class="mb-3">
                            <label for="inputLogin" class="form-label text-body">Логин</label>
                            <input class="form-control rounded-3" id="inputLogin" placeholder="example@sarli64.ru"
                                v-model="inputLogin">
                        </div>
                        <div class="mb-3">
                            <label for="inputPassword" class="form-label text-body">Пароль</label>
                            <input type="password" id="inputPassword" class="form-control"
                                aria-describedby="passwordHelpBlock" placeholder="" v-model="inputPassword">
                        </div>
                        <button class="w-100 mb-2 btn btn-lg rounded-3 btn-success" @click="loginButton">Войти</button>
                        <small class="text-body-secondary">Логин и пароль выдаёт учитель или администратор</small>
                        <hr class="my-4 text-bg-secondary">
                        <a :href="`https://id.vk.com/auth?${query}`" class="w-100 py-2 mb-2 btn btn-primary rounded-3">
                            <svg width="20" height="20" viewBox="0 0 101 100" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_2_2)">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M7.52944 7.02944C0.5 14.0589 0.5 25.3726 0.5 48V52C0.5 74.6274 0.5 85.9411 7.52944 92.9706C14.5589 100 25.8726 100 48.5 100H52.5C75.1274 100 86.4411 100 93.4706 92.9706C100.5 85.9411 100.5 74.6274 100.5 52V48C100.5 25.3726 100.5 14.0589 93.4706 7.02944C86.4411 0 75.1274 0 52.5 0H48.5C25.8726 0 14.5589 0 7.52944 7.02944ZM17.3752 30.4169C17.9168 56.4169 30.9167 72.0418 53.7084 72.0418H55.0003V57.1668C63.3753 58.0001 69.7082 64.1252 72.2498 72.0418H84.0835C80.8335 60.2085 72.2914 53.6668 66.9581 51.1668C72.2914 48.0835 79.7915 40.5835 81.5831 30.4169H70.8328C68.4995 38.6669 61.5836 46.1668 55.0003 46.8751V30.4169H44.2499V59.2501C37.5833 57.5835 29.1668 49.5002 28.7918 30.4169H17.3752Z"
                                        fill="white" />
                                </g>
                                <defs>
                                <clipPath id="clip0_2_2">
                                    <rect width="100" height="100" fill="white" transform="translate(0.5)" />
                                </clipPath>
                            </defs>
                        </svg>
                        Войти с VK ID
                    </a>
                </form>
            </div>
        </div>
    </div>
</div></template>