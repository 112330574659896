import axios from 'axios'
import { getAuthShort } from './auth.js';

const SERVER = 'https://api.kpd64.ru/'

export function absoluteURL(url='',enableRandomParams=false) {
    let randomParam = ''
    if (enableRandomParams) randomParam = url.includes('?') ? `&randomParam=${Math.floor(Math.random() * 100000000)}` : `?randomParam=${Math.floor(Math.random() * 100000000)}`;
    if (url.includes('://')) return url
    return `${SERVER}${url}${randomParam}`
}

/**
 * Отправляет запрос с авторизацией
 * @param {string} urlPath Полная ссылка (https://...) или имя файла в директории сервера (test.php)
 * @param {any} responseData То, что отправит
 * @returns {object}
 */
export async function httpResponseAuth(urlPath,responseData='',notifyError=true,errorReturn=null) {

    let result

    await axios.post( absoluteURL(urlPath) ,JSON.stringify({
        auth: getAuthShort(),
        data: responseData
    }))
    .then( function (response) {
        result =  response.data
        if (!result.isValid) {
            if (notifyError) alert(`ОШИБКА ${result.error.code}:\n${result.error.message}`)
            console.error(`ОШИБКА ${result.error.code}:\n${result.error.message}`)
        } else if (!result || !result.isValid) {
            if (notifyError) alert(`ОШИБКА (${absoluteURL(urlPath)}):\n${response.statusText}`)
            console.error(`ОШИБКА (${absoluteURL(urlPath)}):\n${response}`)
        }
    })
    .catch(function (error) {
        if (notifyError) {
            alert(`Возникла ошибка при попытке отправления данных на ${absoluteURL(urlPath)}.\n${error}`)
            console.error(error);
        }
        result = {
            isValid: false,
            error: {
                message: error,
                code: error.response.status
            },
            data: errorReturn
        }
    });
    return result
}

/**
 * Отправляет запрос без авторизациии
 * @param {string} urlPath Полная ссылка (https://...) или имя файла в директории сервера (test.php)
 * @param {any} responseData То, что отправит
 * @returns {object}
 */
export async function httpResponse(urlPath,responseData,notifyError=true,errorReturn=null) {

    let result

    await axios.post( absoluteURL(urlPath) ,JSON.stringify({
        data: responseData
    }))
    .then( function (response) {
        result =  response.data
        if (result && !result.isValid) {
            if (notifyError) alert(`ОШИБКА ${result.error.code}:\n${result.error.message}`)
            console.error(`ОШИБКА ${result.error.code}:\n${result.error.message}`)
        } else if (!result || !result.isValid) {
            if (notifyError) alert(`ОШИБКА (${absoluteURL(urlPath)}):\n${response.statusText}`)
            console.error(`ОШИБКА (${absoluteURL(urlPath)}):\n${response}`)
        }
    })
    .catch(function (error) {
        console.error(error);
        result = {
            isValid: false,
            error: {
                message: error,
                code: error.response.status
            },
            data: errorReturn
        }
    });
    return result
}

/**
 * Получает ответ от сервера
 * @param {string} urlPath Полная ссылка (https://...) или имя файла в директории сервера (test.php)
 * @param {any} responseData То, что отправит
 * @returns {object}
 */
export async function httpGet(urlPath,notifyError=true,errorReturn=null,enableRandomParams=false) {

    let result

    await axios.get( absoluteURL(urlPath,enableRandomParams))
    .then( function (response) {
        result =  response.data
        if (result && !result.isValid) {
            if (notifyError) alert(`ОШИБКА ${result.error.code} (${absoluteURL(urlPath,enableRandomParams)}):\n${result.error.message}`)
            console.error(`ОШИБКА ${result.error.code} (${absoluteURL(urlPath,enableRandomParams)}):\n${result.error.message}`)
        } else if (!result || !result.isValid) {
            if (notifyError) alert(`ОШИБКА (${absoluteURL(urlPath,enableRandomParams)}):\n${response.statusText}`)
            console.error(`ОШИБКА (${absoluteURL(urlPath,enableRandomParams)}):\n${response}`)
        }
    })
    .catch(function (error) {
        console.error(error);
        result = {
            isValid: false,
            error: {
                message: error,
                code: error.response.status
            },
            data: errorReturn
        }
    });
    return result
}

export function getParameterFromURL(parameter) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(parameter);
  }