<script setup>
/* eslint-disable no-unused-vars */
import AuthApp from '@/AuthApp.vue';
import { ref } from 'vue'
import { getTheme } from './components/func/const';

// Theme
const darkTheme = ref(getTheme())

</script>

<template>
<div :data-bs-theme="darkTheme ? 'dark' : 'light'" :style="`min-height: 100vh;background-color: ${darkTheme ? '#1a1d1f' : '#e0e6e9'};`">
    <Suspense>
        <AuthApp/>
        <template #fallback>
            <div>
                <div class="position-absolute top-50 start-50 translate-middle">
                <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            </div>
        </template>
    </Suspense>
</div>
</template>

<style>
@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.2/font/bootstrap-icons.min.css");
</style>