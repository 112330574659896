import jsCookie from 'js-cookie';
import { httpResponseAuth } from './request.js'

/**
 * Возвращает объект с данными авторизации
 * @returns {object}{ id: number, token: string, level: number }
 */
export function getAuth() {
    return {
        id: jsCookie.get('authId'),
        token: jsCookie.get('authToken'),
        level: parseInt(jsCookie.get('authLevel')),
        grade: jsCookie.get('authGrade')
    }
}

/**
 * Какой класс у человека
 * @returns {boolean}
 */
export function anyGrade(grade) {
    const authData = getAuth()
    return authData.level < 3 || !authData.grade || authData.grade == 'null' || authData.grade == grade
}

/**
 * Возвращает объект с данными авторизации без данных об уровне
 * @returns {object}{ id: number, token: string }
 */
export function getAuthShort() {
    return {
        id: jsCookie.get('authId'),
        token: jsCookie.get('authToken')
    }
}

export async function isValidAuthSession() {
    const authDataPre = getAuth()
    let authResult = {}
    if (authDataPre.id && authDataPre.token) {
        // { isValid: false, error: 0 }
        authResult = await httpResponseAuth('auth/check.php','',false)
        if (authResult.isValid) {
            jsCookie.set('authLevel',authResult.data.level, { expires: 365 })
            jsCookie.set('authGrade',authResult.data.grade, { expires: 365 })
        }
    } else {
        authResult.error = {
            code: 401
        }
        authResult.isValid = false
    }
    return authResult
}

export function chechAuthParams() {
    const url = window.location.href
    var questionMarkIndex = url.indexOf('?');
    if (questionMarkIndex !== -1) {
        var queryString = url.substring(questionMarkIndex + 1);
        var params = queryString.split('&');
        var queryParams = {};
        for (var i = 0; i < params.length; i++) {
            var pair = params[i].split('=');
            queryParams[pair[0]] = pair[1];
        }
        if (queryParams.id && queryParams.token) {
            jsCookie.set('authId',queryParams.id, { expires: 365 })
            jsCookie.set('authToken',queryParams.token, { expires: 365 })
            window.location.href=url.split('?')[0]
        } else if (queryParams.invite) {
            return queryParams.invite
        }
    }
    return undefined
}

export function authLogout() {
    const url = window.location.href
    jsCookie.remove('authId')
    jsCookie.remove('authToken')
    window.location.href=url
}