<script setup>
/* eslint-disable */
import { httpGet, httpResponseAuth } from './func/request'
import { ref } from 'vue'
import * as XLSX from "xlsx";
import { EVENT_LEVELS, EVENT_RESULTS, EVENT_TYPES, copyObj, getDateDMY } from './func/const'

const balanceProps = ref( (await httpGet('data/balance_props.json',false,[],true)).data )
const studentsRecords = ref( (await httpResponseAuth('records/list.php')).data )

const studentsPoints = ref({})
const gradesPoints = ref({})
const letsPoints = ref({})

const studentsPointsArr = ref([])
const gradesPointsArr = ref([])

const pagePrefers = ref( {
    showTable: false,
    currentlyEdit: false,
    ignoreNotChecked: false,
    oldBalanceProps: copyObj(balanceProps.value),
    sortType: 0
} )

function calculatePoints(record,ignoreNotChecked = pagePrefers.value.ignoreNotChecked) {
    if ( (record.checked == 1) || (ignoreNotChecked && record.checked == 0) ) return balanceProps.value[record.eventLevel][record.eventType][record.eventResult]
    return 0
}

function calculateTable() {
    studentsPoints.value = {}
    gradesPoints.value = {}
    letsPoints.value = {}

    studentsRecords.value.forEach(record => {
        let points = calculatePoints(record)
        if (studentsPoints.value[record.studentName]) {
            if (points > 0) studentsPoints.value[record.studentName].count++
            studentsPoints.value[record.studentName].points += points
        } else {
            studentsPoints.value[record.studentName] = {
                grade: record.studentGrade,
                count: points > 0 ? 1 : 0,
                points: points
            }
        }
        if (gradesPoints.value[record.studentGrade]) {
            if (points > 0) gradesPoints.value[record.studentGrade].count++
            gradesPoints.value[record.studentGrade].points += points
        } else {
            gradesPoints.value[record.studentGrade] = {
                count: points > 0 ? 1 : 0,
                points: points
            }
        }
    })

    studentsPointsArr.value = []
    gradesPointsArr.value = []
    studentsRecords.value.forEach((record) => {
        if (!studentsPointsArr.value.includes(record.studentName)) {
            studentsPointsArr.value.push(record.studentName)
        }
        if (!gradesPointsArr.value.includes(record.studentGrade)) {
            gradesPointsArr.value.push(record.studentGrade)
        }
    })
    studentsPointsArr.value.sort((a, b) => studentsPoints.value[b].points-studentsPoints.value[a].points)
    gradesPointsArr.value.sort((a, b) => gradesPoints.value[b].points-gradesPoints.value[a].points)
}
calculateTable()

async function saveNewProps() {
    const responseResult = await httpResponseAuth('records/balanceprops_update.php',balanceProps.value)
    if (responseResult.isValid) {
        pagePrefers.value.oldBalanceProps = copyObj(balanceProps.value)
        pagePrefers.value.currentlyEdit = false
        calculateTable()
    }
}


// EXCEL
const HEADERS = ['ФИО', 'Класс', 'Количество записей', 'Баллы']
function exportExcel() {
    const studentPointsXLSX = copyObj(studentsPointsArr.value)
    const TABLE_ARRAY = [HEADERS]
    studentPointsXLSX.forEach(el => {
        TABLE_ARRAY.push( [
            el,
            studentsPoints.value[el].grade,
            studentsPoints.value[el].count,
            studentsPoints.value[el].points
        ] )
    });
    // const worksheet = XLSX.utils.json_to_sheet(studentRecords)
    const worksheet = XLSX.utils.aoa_to_sheet(TABLE_ARRAY)

    // Установка размера колонок
    worksheet['!cols'] = [
    { wch: 27 }, // ФИО
    { wch: 5 }, // Класс
    { wch: 18 }, // Записей
    { wch: 7 }, // Баллов
    ];

    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Рейтинг учеников')
    XLSX.writeFile(workbook, `РЕЙТИНГ КПД НА ${getDateDMY()}.xlsx`)
}

function calculateTableAsync() {
    setTimeout( ()=>{ calculateTable() },1 )
}

</script>

<template>
    <div class="p-3">
        <div class="card mb-3">
            <div class="card-body">
                <h2 class="mb-3">Таблица разбаловки</h2>
                <div class="mb-1">
                    <button class="btn btn-secondary mx-1 my-1" @click="pagePrefers.showTable=!pagePrefers.showTable" :disabled="pagePrefers.currentlyEdit"><i :class="`bi bi-eye${pagePrefers.showTable ? '-slash': ''}`"></i> {{!pagePrefers.showTable ? 'Показать таблицы' : 'Скрыть таблицы'}}</button>
                    <button v-show="pagePrefers.showTable" class="btn btn-primary mx-1 my-1" @click="saveNewProps()" :disabled="!(pagePrefers.currentlyEdit && JSON.stringify(pagePrefers.oldBalanceProps) != JSON.stringify(balanceProps) )"><i class="bi bi-floppy"></i> Сохранить</button>
                    <button v-show="pagePrefers.showTable" class="btn btn-warning mx-1 my-1" @click="
                    if (pagePrefers.currentlyEdit) {
                        balanceProps = copyObj(pagePrefers.oldBalanceProps);
                        pagePrefers.currentlyEdit = false;
                    } else {
                        pagePrefers.currentlyEdit = true;
                    }
                    "><i :class="`bi bi-${pagePrefers.currentlyEdit ? 'arrow-return-left' : 'pencil'}`"></i> {{ !pagePrefers.currentlyEdit ? 'Редактировать' : 'Отменить изменения' }}</button>
                </div>
                <div v-if="pagePrefers.showTable">
                    <div class="card mb-3" v-for="(level, levelInd) in EVENT_LEVELS" :key="level">
                        <div class="card-body">
                            <h3>{{ level }}</h3>
                            <table class="table">
                                <thead>
                                    <tr>
                                    <th scope="col"></th>
                                    <th v-for="(result) in EVENT_RESULTS" :key="result" scope="col">{{ result }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(type, typeInd) in EVENT_TYPES" :key="type">
                                        <th scope="row">{{ type }}</th>
                                        <td v-if="!pagePrefers.currentlyEdit" v-for="(result, resultInd) in EVENT_RESULTS" :key="result">{{ balanceProps[levelInd][typeInd][resultInd] }}</td>
                                        <td v-else v-for="(result, resultInd) in EVENT_RESULTS" :key="resultInd"><input type="number" class="form-control" v-model="balanceProps[levelInd][typeInd][resultInd]"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card mb-3">
            <div class="card-body">
                <h2 class="mb-3">Сохранение данных</h2>
                <div class="form-check form-switch mb-3">
                        <input class="form-check-input" type="checkbox" role="switch" id="shortEventType" v-model="pagePrefers.ignoreNotChecked" @input="calculateTableAsync()">
                        <label class="form-check-label text-body" for="shortEventType">Учитывать непроверенные записи</label>
                        <div class="form-text" for="">Если включить, при счёте также будут учитываться ещё непровернные записи</div>
                </div>
                <button type="button" class="btn btn-success mx-1 my-1" @click="exportExcel"><i class="bi bi-download"></i> Сохранить как таблицу Excel</button>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <h2 class="mb-3">Подсчёт баллов учеников</h2>
                <div class="form-check form-switch mb-3">
                        <input class="form-check-input" type="checkbox" role="switch" id="shortEventType" v-model="pagePrefers.ignoreNotChecked" @input="calculateTableAsync()">
                        <label class="form-check-label text-body" for="shortEventType">Учитывать непроверенные записи</label>
                        <div class="form-text" for="">Если включить, при счёте также будут учитываться ещё непровернные записи</div>
                </div>
                <div class="mb-3">
                    <button @click="pagePrefers.sortType = 0" :class="`mx-1 my-1 btn btn${ pagePrefers.sortType == 0 ? '' : '-outline'}-primary`">Среди учеников</button>
                    <button @click="pagePrefers.sortType = 1" :class="`mx-1 my-1 btn btn${ pagePrefers.sortType == 1 ? '' : '-outline'}-primary`">Среди классов</button>
                    
                </div>
                <div v-if="pagePrefers.sortType == 0">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>ФИО</th>
                                <th>Класс</th>
                                <th>Количество записей</th>
                                <th>Баллы</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(student,i) in studentsPointsArr">
                                <td>{{ i+1 }}</td>
                                <td>{{ student }}</td>
                                <td>{{ studentsPoints[student].grade }}</td>
                                <td>{{ studentsPoints[student].count }}</td>
                                <td>{{ studentsPoints[student].points }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-else-if="pagePrefers.sortType == 1">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Класс</th>
                                <th>Количество записей</th>
                                <th>Баллы</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(grade,i) in gradesPointsArr">
                                <td>{{ i+1 }}</td>
                                <td>{{ grade }}</td>
                                <td>{{ gradesPoints[grade].count }}</td>
                                <td>{{ gradesPoints[grade].points }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
@media only screen and (max-width: 600px) {
  table {
    width: 100%;
    overflow-x: auto;
    display: block;
  }
  th, td {
    min-width: 100px;
  }
}
</style>