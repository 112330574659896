/* eslint-disable */

export const ROUTER_PAGES = {
    '': {
        perm: Infinity
    },
    students: {
        perm: 2
    },
    settings: {
        perm: 2
    },
    points: {
        perm: 2
    }
}

export const ROUTER_PILLS = [
    {
        displayName: 'Записи',
        page: '',
        perm: Infinity
    },
    {
        displayName: 'Разбаловка',
        page: 'points',
        perm: 2
    },
    {
        displayName: 'Ученики',
        page: 'students',
        perm: 2
    },
    {
        displayName: 'Настройки',
        page: 'settings',
        perm: 2
    }
]

export function getPage() {
    const url = window.location.href
    return url.split('://')[1].split('/')[1]
}

export function getDomain() {
    const url = window.location.href
    return url.split('://')[1].split('/')[0]
}