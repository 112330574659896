<script setup>
/* eslint-disable */
import { defineProps } from 'vue'
const { errorCode } = defineProps({
    errorCode: {
        type: Number,
        default: 404
    }
})

const errorText = {
    404: 'Не найдено!',
    403: 'Запрещено!'
}

</script>

<template>
    <div class="position-absolute top-50 start-50 translate-middle">
        <h1 class="text-body text-center">{{errorCode}}</h1>
        <h3 class="text-body text-center">{{errorText[errorCode]}}</h3>
    </div>
</template>