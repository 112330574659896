<script setup>
import LoadingStamp from '../ui/LoadingStamp.vue';
import SettingsGeneral from './SettingsGeneral.vue';
import SettingsAdmins from './SettingsAdmins.vue';
import SettingsStorage from './SettingsStorage.vue';
import SettingsRecords from './SettingsRecords.vue';
import { ROUTER_PAGES } from '../func/routers';
import { getAuth } from '../func/auth';
import ErrorPage from '../ui/ErrorPage.vue';

const authData = getAuth()
</script>

<template>
    <div class="p-3" v-if="authData.level <= ROUTER_PAGES['settings'].perm">
        <h1 class="text-body text-center mb-4 bt-3">Настройки</h1>

        <div class="card p-3 mb-3">
            <div class="card-body">
                <h3 class="card-title">Общее</h3>
                <Suspense>
                    <SettingsGeneral/>
                    <template #fallback>
                        <LoadingStamp/>
                    </template>
                </Suspense>
            </div>
        </div>
        <div class="card p-3 mb-3">
            <div class="card-body">
                <h3 class="card-title mb-1">Доступ к панели</h3>
                <Suspense>
                    <SettingsAdmins/>
                    <template #fallback>
                        <LoadingStamp/>
                    </template>
                </Suspense>
            </div>
        </div>
        <div class="card p-3 mb-3">
            <div class="card-body">
                <h3 class="card-title mb-4">Записи учеников</h3>
                <Suspense>
                    <SettingsRecords/>
                    <template #fallback>
                        <LoadingStamp/>
                    </template>
                </Suspense>
            </div>
        </div>
        <div class="card p-3 mb-3">
            <div class="card-body">
                <h3 class="card-title mb-4">Хранилище</h3>
                <Suspense>
                    <SettingsStorage/>
                    <template #fallback>
                        <LoadingStamp/>
                    </template>
                </Suspense>
            </div>
        </div>
    </div>
    <ErrorPage :error-code="403" v-else/>
</template>

<style>
/* Для мобильных устройств */
@media only screen and (max-width: 600px) {
  table {
    width: 100%;
    overflow-x: auto;
    display: block;
  }
  th, td {
    min-width: 100px;
  }
}
</style>