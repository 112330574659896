import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'

import RecordsManageAsync from './components/async/RecordsManageAsync.vue'
import StudentsManageAsync from './components/async/StudentsManageAsync.vue'
import BalancePageAsync from './components/async/BalancePageAsync.vue'
import ErrorPage from './components/ui/ErrorPage.vue'
import PageBlankVue from './components/SettingsPages/PageBlank.vue'

// import MainPage from './components/MainPage.vue'

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            name: 'Settings',
            path: '/settings',
            component: PageBlankVue
        },
        // {
        //     name: 'Rating',
        //     path: '/records',
        //     component: RecordsManageAsync
        // },
        {
            name: 'Balance',
            path: '/points',
            component: BalancePageAsync
        },
        {
            name: 'Students',
            path: '/students',
            component: StudentsManageAsync
        },
        {
            name: 'Main',
            path: '/',
            component: RecordsManageAsync
        },
        {
            // path: "*",
            path: "/:catchAll(.*)",
            name: "NotFound",
            component: ErrorPage,
        }
    ]
})

createApp(App).use(router).mount('#app')
