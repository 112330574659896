<script setup>
import { ref } from 'vue'
import { CLASS_LET, CLASS_NUM, isMobile } from '../func/const';
import { getAuth } from '../func/auth';
import { PERM_NAMES } from '../func/const'
import { httpResponse, httpResponseAuth } from '../func/request';

const GRADES = []
for (let num of CLASS_NUM) {
    for (let lett of CLASS_LET) {
        GRADES.push(`${num}${lett}`)
    }
}

const authData = getAuth()
const isMobileDevice = isMobile()

const panelAdmins = ref( (await httpResponseAuth('auth/admin_list.php',{},true,[])).data )
const ids = panelAdmins.value.map( (val) => {
    return val.id
})

const panelAdminsVK = ref( (await httpResponse('vk/userdata.php',ids)).data.response )
const panelAdminsById = ref({})

panelAdmins.value.map(element => {
    panelAdminsById.value[element.id] = element
});

const addingAdmin = ref({
    info: {},
    grade: '',
    input: '',
    role: 3,
    name: ''
})

async function loadAdminInfo(tag) {
    addingAdmin.value.input = ''
    if (tag.indexOf('vk.com/') != -1) tag = tag.split('vk.com/')[1]
    else if (tag.indexOf('@') != -1) tag = tag.substr(1)
    const adminIdInfo = (await httpResponse('vk/tag.php',tag))
    if (adminIdInfo.isValid && adminIdInfo.data.response.type == 'user') {
        let preResult = await httpResponse('vk/userdata.php',[adminIdInfo.data.response.object_id])
        if (!preResult.isValid) return
        preResult = preResult.data
        addingAdmin.value.info = preResult.response[0]
        addingAdmin.value.name = `https://vk.com/${preResult.response[0].screen_name}`
    } else {
        addingAdmin.value.input = 'is-invalid'
    }
}

async function confirmAddedAdmin() {
    // alert(PERM_NAMES[addingAdmin.value.role])
    const result = await httpResponseAuth('auth/admin_add.php',{
        id: addingAdmin.value.info.id,
        perm: parseInt(addingAdmin.value.role),
        grade: addingAdmin.value.grade
    })
    if (result.isValid) {
        panelAdminsVK.value.push(addingAdmin.value.info)
        panelAdmins.value.push({
            id: addingAdmin.value.info.id.toString(),
            perm: parseInt(addingAdmin.value.role),
            grade: addingAdmin.value.grade
        })
        panelAdminsById.value[addingAdmin.value.info.id] = {
            id: addingAdmin.value.info.id.toString(),
            perm: parseInt(addingAdmin.value.role),
            grade: addingAdmin.value.grade
        }

        addingAdmin.value.info={}
        addingAdmin.value.name=''
    }
}

async function confirmDeletionAdmin(user) {
    // alert(PERM_NAMES[addingAdmin.value.role])
    const responseResult = await httpResponseAuth('auth/admin_delete.php',user)
    if (responseResult.isValid) {
        for (let i in panelAdminsVK.value) {
            if (panelAdminsVK.value[i].id == user) panelAdminsVK.value.splice(i,1)
        }
    }
}

// LINKS
const inviteLinks = ref( (await httpResponseAuth('auth/invitation_list.php')).data )
const inviteLinksPrefs = ref({
    hideLinks: true,
    creation: {
        grade: '',
        perm: 3,
        broke: 0,
        brokeBool: false,
        link: ''
    }
})

async function createLink() {
    inviteLinksPrefs.value.creation.broke = inviteLinksPrefs.value.creation.brokeBool ? 1 : 0
    const responseResult = await httpResponseAuth('auth/invitation_create.php',inviteLinksPrefs.value.creation)
    if (responseResult.isValid) {
        inviteLinksPrefs.value.creation.link = responseResult.data
        inviteLinks.value.push(inviteLinksPrefs.value.creation)
    } 
}

async function deleteLink(link) {
    const responseResult = await httpResponseAuth('auth/invitation_delete.php',link)
    if (responseResult.isValid) {
        let index = 0
        inviteLinks.value.forEach( (elem,ind) => {
            if (elem.link == link) index = ind
        } )
        inviteLinks.value.splice(index, 1)
    }
}

function copyInviteLink(link) {
    navigator.clipboard.writeText(link)
}
</script>

<template>
    <div class="card-body">
        <div class="card mb-3" v-if="authData.level < 2">
        <div class="card-body">
            <h5>Добавление по никнейму VK</h5>
            <div class="input-group mb-3">
                <input type="text" :disabled="Boolean(addingAdmin.info.id)" :class="`form-control ${addingAdmin.input}`" placeholder="https://vk.com/username или @username" v-model="addingAdmin.name" aria-describedby="button-addon2">
                <button :disabled="Boolean(addingAdmin.info.id)" class="btn btn-success" type="button" id="button-addon2" @click="loadAdminInfo(addingAdmin.name)">Добавить</button>
            </div>
            <div v-if="Boolean(addingAdmin.info.id)">
                <div class="mb-3">
                    <label>Выберите уровень прав</label>
                    <select class="form-select" id="selectRole" v-model="addingAdmin.role">
                        <option value="3">Проверяющий</option>
                        <option value="2">Менеджер</option>
                        <option value="1">Администратор</option>
                    </select>
                    <label class="form-text" for="selectRole">Менеджер не может добавлять новых администраторов и очищать записи, а проверяющий имеет доступ только к записям учеников.</label>
                    <div v-if="addingAdmin.role == 3">
                        <label>Класс для проверяющего</label>
                        <select class="form-select" id="selectGrade" v-model="addingAdmin.grade">
                            <option value="">Любой класс</option>
                            <option v-for="grade in GRADES" :key="grade" :value="grade">{{grade}}</option>
                        </select>
                        <label class="form-text" for="selectRole">Указав класс, проверящий сможет проверять только его</label>
                    </div>
                </div>
                <div class="alert alert-primary">
                    Пользователь <strong><img :src="addingAdmin.info.photo_50" style="border-radius:50%;" width="25"> <a target="_blank" :href="`https://vk.com/${addingAdmin.info.screen_name}`" class="link-underline link-underline-opacity-0">{{ addingAdmin.info.first_name }} {{ addingAdmin.info.last_name }}</a></strong> будет добавлен в список администраторов. Добавив его в этот список, вы разрешите ему входить в эту панель
                    <div>
                        <button class="btn btn-primary mx-1 my-1" @click="confirmAddedAdmin">Добавить</button>
                        <button class="btn btn-secondary mx-1 my-1" @click="addingAdmin.info={};addingAdmin.name=''">Отмена</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
        <div class="card mb-3">
            <div class="card-body">
                <h5>Список пользователей с доступом</h5>
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">Имя</th>
                            <th scope="col">Уровень прав</th>
                            <th scope="col" v-if="authData.level<2">Действия</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="user in panelAdminsVK" :key="user.id">
                            <td><a :href="`https://vk.com/${user.screen_name}`" target="_blank" class="link-underline link-underline-opacity-0"><img :src="user.photo_50" style="border-radius:50%;" width="25"> {{ user.first_name }} {{ user.last_name }}</a></td>
                            <td v-if="panelAdminsById[user.id].perm < 3 || !panelAdminsById[user.id].grade">{{ PERM_NAMES[panelAdminsById[user.id].perm] }}</td>
                            <td v-else>{{ PERM_NAMES[panelAdminsById[user.id].perm] }} ({{ panelAdminsById[user.id].grade }})</td>
                            <td v-if="authData.level<2">
                                <button type="button" class="btn btn-danger" :disabled="panelAdminsById[user.id].perm == 0" @click="confirmDeletionAdmin(user.id)"><i class="bi bi-person-dash"></i>{{isMobileDevice ? '' : ' Удалить'}}</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="card mb-3" v-if="authData.level < 2">
            <div class="card-body">
                <h5>Создание ссылки-приглашения</h5>
                <p>Если вы не знаете аккаунт проверяющего в VK, вы можете создать и отправить ему пригласительную ссылку</p>
                <label>Выберите класс</label>
                <select class="form-select mb-1" id="selectGrade" v-model="inviteLinksPrefs.creation.grade">
                    <option value="">Проверяющий выберет сам</option>
                    <option v-for="grade in GRADES" :key="grade" :value="grade">{{grade}}</option>
                </select>
                <div class="form-check mb-3">
                    <input class="form-check-input" type="checkbox" v-model="inviteLinksPrefs.creation.brokeBool" id="flexCheckDefault">
                    <label class="form-check-label" for="flexCheckDefault">
                        Одноразовая ссылка
                    </label>
                </div>
                <button @click="createLink()" class="btn btn-primary">Создать ссылку</button>
                <div class="mt-4" v-if="inviteLinksPrefs.creation.link">
                    <div class="input-group mb-3">
                        <input class="form-control" readonly :value="`https://kpd64.ru/invite/${ inviteLinksPrefs.creation.link}`">
                        <button class="input-group-text" @click="copyInviteLink(`https://kpd64.ru/invite/${inviteLinksPrefs.creation.link}`)"><i class="bi bi-copy"></i></button>
                    </div>
                    <div class="mb-3">
                        <a class="btn btn-outline-info mx-1 my-1" target="_blank" :href="`https://t.me/share/url?url=${encodeURIComponent(`https://kpd64.ru/invite/${inviteLinksPrefs.creation.link}`)}&text=${encodeURIComponent('Проверяйте записи учеников в КПД')}`"><i class="bi bi-telegram"></i></a>
                        <a class="btn btn-primary mx-1 my-1" target="_blank" :href="`https://vk.com/share.php?url=${encodeURIComponent(`https://kpd64.ru/invite/${inviteLinksPrefs.creation.link}`)}`"><svg width="20" height="20" viewBox="0 0 101 100" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_2_2)"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.52944 7.02944C0.5 14.0589 0.5 25.3726 0.5 48V52C0.5 74.6274 0.5 85.9411 7.52944 92.9706C14.5589 100 25.8726 100 48.5 100H52.5C75.1274 100 86.4411 100 93.4706 92.9706C100.5 85.9411 100.5 74.6274 100.5 52V48C100.5 25.3726 100.5 14.0589 93.4706 7.02944C86.4411 0 75.1274 0 52.5 0H48.5C25.8726 0 14.5589 0 7.52944 7.02944ZM17.3752 30.4169C17.9168 56.4169 30.9167 72.0418 53.7084 72.0418H55.0003V57.1668C63.3753 58.0001 69.7082 64.1252 72.2498 72.0418H84.0835C80.8335 60.2085 72.2914 53.6668 66.9581 51.1668C72.2914 48.0835 79.7915 40.5835 81.5831 30.4169H70.8328C68.4995 38.6669 61.5836 46.1668 55.0003 46.8751V30.4169H44.2499V59.2501C37.5833 57.5835 29.1668 49.5002 28.7918 30.4169H17.3752Z" fill="white" /></g><defs><clipPath id="clip0_2_2"><rect width="100" height="100" fill="white" transform="translate(0.5)" /></clipPath></defs></svg></a>
                    </div>
                    <img class="img-thumbnail" :src="`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${encodeURIComponent(`https://kpd64.ru/invite/${inviteLinksPrefs.creation.link}`)}`">
                </div>
            </div>
        </div>
        <div class="card mb-3" v-if="authData.level < 2">
            <div class="card-body">
                <h5>Ссылки-приглашения</h5>
                <p>Текущий список пригласительных ссылок</p>
                <button class="btn btn-secondary btn-sm" v-if="inviteLinksPrefs.hideLinks" @click="inviteLinksPrefs.hideLinks=!inviteLinksPrefs.hideLinks"><i class="bi bi-eye"></i> Показать ссылки</button>
                <button class="btn btn-secondary  btn-sm" v-else @click="inviteLinksPrefs.hideLinks=!inviteLinksPrefs.hideLinks"><i class="bi bi-eye-slash"></i> Скрыть ссылки</button>

                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">Ссылка</th>
                            <th scope="col">Класс</th>
                            <th scope="col">Тип</th>
                            <th scope="col">Действия</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="invite in inviteLinks" :key="invite.link">
                            <td class="input-group" v-if="!isMobileDevice">
                                <input class="form-control" readonly :value="`https://kpd64.ru/invite/${ inviteLinksPrefs.hideLinks ? 'X'.repeat(invite.link.length) : invite.link }`">
                                <!-- <a class="btn btn-outline-secondary" target="_blank" :href="`https://vk.com/share.php?url=${encodeURIComponent(`https://kpd64.ru/invite/${invite.link}`)}`"><svg width="20" height="20" viewBox="0 0 101 100" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_2_2)"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.52944 7.02944C0.5 14.0589 0.5 25.3726 0.5 48V52C0.5 74.6274 0.5 85.9411 7.52944 92.9706C14.5589 100 25.8726 100 48.5 100H52.5C75.1274 100 86.4411 100 93.4706 92.9706C100.5 85.9411 100.5 74.6274 100.5 52V48C100.5 25.3726 100.5 14.0589 93.4706 7.02944C86.4411 0 75.1274 0 52.5 0H48.5C25.8726 0 14.5589 0 7.52944 7.02944ZM17.3752 30.4169C17.9168 56.4169 30.9167 72.0418 53.7084 72.0418H55.0003V57.1668C63.3753 58.0001 69.7082 64.1252 72.2498 72.0418H84.0835C80.8335 60.2085 72.2914 53.6668 66.9581 51.1668C72.2914 48.0835 79.7915 40.5835 81.5831 30.4169H70.8328C68.4995 38.6669 61.5836 46.1668 55.0003 46.8751V30.4169H44.2499V59.2501C37.5833 57.5835 29.1668 49.5002 28.7918 30.4169H17.3752Z" fill="white" /></g><defs><clipPath id="clip0_2_2"><rect width="100" height="100" fill="white" transform="translate(0.5)" /></clipPath></defs></svg></a>                  -->
                                <!-- <a class="btn btn-outline-secondary" target="_blank" :href="`https://t.me/share/url?url=${encodeURIComponent(`https://kpd64.ru/invite/${invite.link}`)}&text=${encodeURIComponent('Проверяйте записи учеников в КПД')}`"><i class="bi bi-telegram"></i></a> -->
                                <button class="input-group-text" @click="copyInviteLink(`https://kpd64.ru/invite/${invite.link}`)"><i class="bi bi-copy"></i></button>
                                <!-- <button class="btn btn-danger"><i class="bi bi-trash"></i></button> -->
                            </td>
                            <td v-else>
                                <p>{{ `https://kpd64.ru/invite/${ inviteLinksPrefs.hideLinks ? 'X'.repeat(invite.link.length) : invite.link }` }}</p>
                                <button class="input-group-text" @click="copyInviteLink(`https://kpd64.ru/invite/${invite.link}`)"><i class="bi bi-copy"></i></button>
                                
                            </td>
                            <td>{{ invite.grade == '' ? 'Доступен выбор' : invite.grade }}</td>
                            <td>{{ invite.broke == 0 ? 'Многоразовая' : 'Одноразовая' }}</td>
                            <td>
                                <button type="button" class="btn btn-danger" @click="deleteLink(invite.link)"><i class="bi bi-trash"></i>{{isMobileDevice ? '' : ' Удалить'}}</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>