<script setup>
import { ref } from 'vue'
import { copyObj } from '@/components/func/const.js'
import { httpGet, httpResponseAuth } from '../func/request';
// Freeze form
const freezeFormToggle = ref((await httpGet('data/closed.json',true,true,true)).data)
const freezeFormToggleOld = ref(freezeFormToggle.value)

// Freeze form
const lockCheckersPanelToggle = ref(!(await httpGet('data/panel_access.json',true,true,true)).data)
const lockCheckersPanelToggleOld = ref(lockCheckersPanelToggle.value)

// Alert message
const alertMessage = ref((await httpGet('data/alert.json',true,true,true)).data)
const alertMessageOld = ref(copyObj(alertMessage.value))

const showAlertMessage = ref(Boolean(alertMessage.value.text))
const showAlertMessageOld = ref(showAlertMessage.value)
const showAlertButton = ref(Boolean(alertMessage.value.buttonText))

if (!showAlertMessage.value) alertMessage.value.color = 'primary'
if (!showAlertButton.value) {
    alertMessage.value.buttonText = 'Перейти'
    alertMessage.value.buttonLink = 'https://kpd64.ru/rating'
}

const isButtonLoading = ref(false)

async function togglesSave() {
    isButtonLoading.value = true
    const resultFreeze = await httpResponseAuth('students/freeze_set.php',freezeFormToggle.value)
    const resultCheckersLock = await httpResponseAuth('auth/admin_checklock.php',!lockCheckersPanelToggle.value)
    const alertObject = {
        text: '',
        color: '',
        buttonText: '',
        buttonLink: ''
    }

    if (showAlertMessage.value) {
        alertObject.text = alertMessage.value.text
        alertObject.color = alertMessage.value.color
        if (showAlertButton.value) {
            alertObject.buttonLink = alertMessage.value.buttonLink
            alertObject.buttonText = alertMessage.value.buttonText
        }
    }
    const resultAlert = await httpResponseAuth('storage/alert_update.php',alertObject)
    
    isButtonLoading.value = !(resultFreeze.isValid && resultAlert && resultCheckersLock)
    showAlertMessageOld.value = showAlertMessage.value
    lockCheckersPanelToggleOld.value = lockCheckersPanelToggle.value
    freezeFormToggleOld.value = freezeFormToggle.value
    alertMessageOld.value = copyObj(alertMessage.value)
}

// const isButtonDisabled = ref(
//     isButtonLoading.value || (JSON.stringify(alertMessage.value) == JSON.stringify(alertMessageOld.value))
// )

</script>

<template>
    <div class="form-check form-switch mb-4 mt-4">
        <input class="form-check-input" type="checkbox" role="switch" id="lockCheckersPanel" v-model="lockCheckersPanelToggle">
        <label class="form-check-label text-body" for="lockCheckersPanel">Закрыть доступ к панели для проверяющих</label>
        <div><label class="form-text" for="lockCheckersPanel">Если включено, то проверяющие классов не смогут войти в панель.</label>
        </div>
    </div>
    <div class="form-check form-switch mb-4 mt-4">
        <input class="form-check-input" type="checkbox" role="switch" id="freezeForm" v-model="freezeFormToggle">
        <label class="form-check-label text-body" for="freezeForm">Запретить заполнение формы</label>
        <div><label class="form-text" for="freezeForm">Когда включено, ученики не могут отправлять новые заявки</label>
        </div>
    </div>
    <div class="form-check form-switch mb-4">
        <input class="form-check-input" type="checkbox" role="switch" id="showAlertMessage" v-model="showAlertMessage">
        <label class="form-check-label text-body" for="showAlertMessage">Сообщение на сайте</label>
        <div><label class="form-text" for="showAlertMessage">Задайте цвет и текст сообщения, которое будут видеть ученики,
                заходя на сайт</label></div>
    </div>
    <div class="mb-4 card" v-if="showAlertMessage">
        <div class="card-body">
            <h4>Сообщение на сайт</h4>
            <input class="form-control mb-4" type="text" placeholder="Сообщение" v-model="alertMessage.text">
            <div class="form-check form-switch mb-2">
                <input class="form-check-input" type="checkbox" role="switch" id="showAlertButton"
                    v-model="showAlertButton">
                <label class="form-check-label text-body" for="showAlertButton">Добавить кнопку</label>
            </div>
            <div class="mb-4" v-if="showAlertButton">
                <div class="input-group mb-2">
                    <span class="input-group-text"><i class="bi bi-fonts"></i></span>
                    <input class="form-control" type="text" placeholder="Текст кнопки" v-model="alertMessage.buttonText">
                </div>
                <div class="input-group">
                    <span class="input-group-text"><i class="bi bi-link-45deg"></i></span>
                    <input class="form-control" type="text" placeholder="Ссылка кнопки" v-model="alertMessage.buttonLink">
                </div>
            </div>
            <div class="mb-2">
                <div v-if="isMobileDevice"><label>Цвет:</label></div>
                <label v-else>Цвет:</label>

                <input type="radio" class="btn-check" name="options-outlined" id="primary-outlined" value="primary"
                    v-model="alertMessage.color" autocomplete="off">
                <label class="btn btn-outline-primary mx-1 my-1" for="primary-outlined"><i class="bi bi-chat"></i></label>

                <input type="radio" class="btn-check" name="options-outlined" id="success-outlined" value="success"
                    v-model="alertMessage.color" autocomplete="off">
                <label class="btn btn-outline-success mx-1 my-1" for="success-outlined"><i class="bi bi-chat"></i></label>

                <input type="radio" class="btn-check" name="options-outlined" id="danger-outlined" value="danger"
                    v-model="alertMessage.color" autocomplete="off">
                <label class="btn btn-outline-danger mx-1 my-1" for="danger-outlined"><i class="bi bi-chat"></i></label>

                <input type="radio" class="btn-check" name="options-outlined" id="warning-outlined" value="warning"
                    v-model="alertMessage.color" autocomplete="off">
                <label class="btn btn-outline-warning mx-1 my-1" for="warning-outlined"><i class="bi bi-chat"></i></label>

                <input type="radio" class="btn-check" name="options-outlined" id="info-outlined" value="info"
                    v-model="alertMessage.color" autocomplete="off">
                <label class="btn btn-outline-info mx-1 my-1" for="info-outlined"><i class="bi bi-chat"></i></label>
            </div>
            <div :class="`alert alert-${alertMessage.color}`" v-if="alertMessage.text">
                <div>{{ alertMessage.text }}</div>
                <a :href="alertMessage.buttonLink" target="_blank"><button v-if="showAlertButton"
                        :class="`mt-3 btn btn-${alertMessage.color}`"><i class="bi bi-box-arrow-up-right"></i> {{
                            alertMessage.buttonText }}</button></a>
            </div>
        </div>
    </div>
    <button type="button" class="btn btn-primary" @click="togglesSave" :disabled="isButtonLoading || (JSON.stringify(alertMessage) == JSON.stringify(alertMessageOld) && freezeFormToggle == freezeFormToggleOld && lockCheckersPanelToggle == lockCheckersPanelToggleOld && showAlertMessage == showAlertMessageOld)  || ((alertMessage.text == '' || (showAlertButton && (alertMessage.buttonLink == '' || alertMessage.buttonText == ''))) && showAlertMessage)"><span
            class="spinner-border spinner-border-sm" aria-hidden="true" v-show="isButtonLoading"></span>{{
                isButtonLoading ? ' Сохранение...' : 'Сохранить' }}</button>
</template>