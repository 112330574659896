<script setup>
import { getStudents, isMobile, shortName } from './func/const.js'
import { ref, computed } from 'vue'
import { httpResponseAuth } from './func/request';

const studentsRaw = await getStudents()
const students = ref(studentsRaw)
const studentsOld = ref(studentsRaw.concat())
const searchStudent = ref('')
const studentsList = ref('')

const studentAddValue = ref('')
const studentAddIndex = ref(students.value.length+1)
const studentsRemoved = ref(0)
const studentsAdded = ref(0)

const manageType = ref(0)
const copyButton = ref('Скопировать текст')

const shortingName = ref(true)

const showSuccessAlert = ref('')

for (let student of studentsRaw) {
    studentsList.value = `${studentsList.value}\n${student}`
}
studentsList.value=studentsList.value.substring(1)
const studentsListOld = ref(studentsList.value)

const foundedStudents = computed( () => {
    let array = students.value.filter( (student) => {
        return student.toLowerCase().indexOf(searchStudent.value.toLowerCase()) !== -1
    })
    return array
} )

const diffLines = computed( () => {
    return studentsList.value.split('\n').length-studentsListOld.value.split('\n').length
} )

const noEmptyLines = computed( () => {
    return studentsList.value.split('\n').indexOf('') == -1
} )
  
function copyText() {
    copyButton.value = 'Скопировано!'
    navigator.clipboard.writeText(studentsList.value)
    setTimeout( ()=>{
        copyButton.value = 'Скопировать текст'
    },3000 )
}

const isMobileDevice = ref(isMobile())

async function updateStudensListTable() {
    showSuccessAlert.value = ''
    studentsOld.value = students.value.concat()
    studentsAdded.value = 0
    studentsRemoved.value = 0
    studentsList.value = ''
    for (let student of students.value) {
        studentsList.value = `${studentsList.value}\n${student}`
    }
    studentsList.value=studentsList.value.substring(1)
    studentsListOld.value = studentsList.value
    showSuccessAlert.value = (await httpResponseAuth('students/update.php',students.value)).isValid
}

async function updateStudensListText() {
    students.value = studentsList.value.split('\n')
    studentsOld.value = students.value.concat()
    studentsAdded.value = 0
    studentsRemoved.value = 0
    studentsListOld.value = studentsList.value
    showSuccessAlert.value = (await httpResponseAuth('students/update.php',students.value)).isValid
}
</script>

<template>
<div class="p-3">
    <h1 class="text-body text-center mb-4 bt-3">Управление списком учеников</h1>
    <!-- <p class="text-body text-center">Управлять как:</p> -->
    <div class="text-center mb-5">
        <div class="btn-group" role="group">
            <input v-model="manageType" type="radio" class="btn-check" :value="0" id="typeTable" autocomplete="off">
            <label class="btn btn-outline-primary" for="typeTable">Таблица</label>

            <input v-model="manageType" type="radio" class="btn-check" :value="1" id="typeText" autocomplete="off">
            <label class="btn btn-outline-primary" for="typeText">Текстовый список</label>
        </div>
    </div>
    <div class="alert alert-success alert-dismissible fade show" role="alert" v-if="showSuccessAlert === true">
        <i class="bi bi-check-circle"></i> Список учеников успешно обновлён!
        <button type="button" class="btn-close" @click="showSuccessAlert=''"></button>
    </div>
    <div class="alert alert-danger alert-dismissible fade show" role="alert" v-else-if="showSuccessAlert === false">
        <i class="bi bi-exclamation-circle"></i> Возникла ошибка отправки.
        <button type="button" class="btn-close" @click="showSuccessAlert=''"></button>
    </div>
    <div v-if="manageType==0">
        <div class="mb-1">
            <button type="button" class="btn btn-primary mx-1 my-1" @click="updateStudensListTable" :disabled="students.toString() == studentsOld.toString()"><i class="bi bi-floppy"></i> Сохранить{{ studentsRemoved > 0 ? ` (${studentsRemoved}` : '' }}<i class="bi bi-person-dash" v-if="studentsRemoved > 0"></i>{{ studentsRemoved > 0 ? `)` : '' }}{{ studentsAdded > 0 ? ` (${studentsAdded}` : '' }}<i class="bi bi-person-add" v-if="studentsAdded > 0"></i>{{ studentsAdded > 0 ? `)` : '' }}</button>
            <button type="button" class="btn btn-danger mx-1 my-1" @click="students = studentsOld.concat();studentsAdded=0;studentsRemoved=0;studentAddValue='';studentAddIndex=students.length" :disabled="students.toString() == studentsOld.toString()"><i class="bi bi-arrow-return-left"></i> Восстановить</button>
        </div>
        <p class="mb-3 text-body">{{ studentsRemoved > 0 ? `${studentsRemoved} удален${studentsRemoved > 1 ? 'о':''}` : '' }}{{studentsAdded > 0 && studentsRemoved > 0 ? ' и ':''}}{{ studentsAdded > 0 ? `${studentsAdded} добавлен${studentsAdded > 1 ? 'о':''}` : '' }}</p>
        
        <div class="card mb-3">
            <div class="card-body">
            <label class="text-body">Добавление ученика</label>
            <div class="input-group mb-3">
                <input type="text" class="form-control" placeholder="ФИО" aria-describedby="button-addon2" v-model="studentAddValue">
                <button class="btn btn-success" type="button" id="button-addon2" @click="studentsAdded++;students.splice(studentAddIndex-1,0,studentAddValue);studentAddValue='';studentAddIndex=students.length+1" :disabled="studentAddValue.length == 0 || studentAddIndex == '' || studentAddIndex > students.length+1 || studentAddIndex < 1">{{ isMobileDevice ? 'Добавить' : 'Добавить ученика'}}</button>
            </div>
            <div class="" v-show="studentAddValue.length > 0">
                <label for="eventCount"  aria-describedby="countMoreInfo">Порядковый номер, куда необходимо вставить ученика</label>
                <input autocomplete="off" type="number" class="form-control" id="eventCount" :placeholder="students.length" min="1" :max="students.length" v-model="studentAddIndex">
                <div v-if="studentAddValue != '' && studentAddIndex != ''">
                    <div id="eventCount" class="form-text" v-if="students.length+1 == studentAddIndex">{{ shortName(studentAddValue) }} будет стоять после: {{ shortName(students[studentAddIndex-2]) }}</div>
                    <div id="eventCount" class="form-text" v-else-if="students.length > studentAddIndex && studentAddIndex != 1">{{ shortName(studentAddValue) }} будет стоять после {{ shortName(students[studentAddIndex-2]) }} и перед {{ shortName(students[studentAddIndex-1]) }}</div>
                    <div id="eventCount" class="form-text" v-else-if="1 == studentAddIndex">{{ shortName(studentAddValue) }} будет стоять перед: {{ shortName(students[studentAddIndex-1]) }}</div>
                </div>
            </div>
            </div>
        </div>
        <div class="card mb-3" v-if="isMobileDevice">
            <div class="card-body">
                <p>Выберите вид отображения имён</p>
                <div class="btn-group" role="group">
                    <input v-model="shortingName" type="radio" class="btn-check" :value="false" id="typeNameLong" autocomplete="off">
                    <label class="btn btn-outline-primary" for="typeNameLong">Полностью</label>

                    <input v-model="shortingName" type="radio" class="btn-check" :value="true" id="typeNameShort" autocomplete="off">
                    <label class="btn btn-outline-primary" for="typeNameShort">Инициалы</label>
                </div>
                <!-- <p class="form-text" for="xddc">Тип отображения применяется только для вас.</p>
                <p class="form-text" for="xddc" v-if="shortingName">Поиск по-прежнему будет работать по полному имени.</p> -->
            </div>
        </div>
        <div class="card mb-3">
            <div class="card-body">
                <div class="mb-3">
                    <div class="input-group">
                        <span class="input-group-text" id="basic-addon1"><i class="bi bi-search"></i></span>
                        <input autocomplete="off" id="searchStudent" type="text" :class="`form-control ${foundedStudents.length == 0 ? 'is-invalid' : ''}`" @input="searchInput" v-model="searchStudent" placeholder="Поиск...">
                    </div>
                </div>
                <table class="table" v-if="foundedStudents.length>0">
                    <thead>
                        <tr>
                            <th scope="col">№</th>
                            <th scope="col">ФИО{{ foundedStudents.length < students.length ? ` (Найдено: ${foundedStudents.length})` : '' }}</th>
                            <th scope="col">Действия</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(name,i) in foundedStudents" :key="i">
                            <td>{{ students.indexOf(name)+1 }}</td>
                            <td>{{ isMobileDevice && shortingName ? shortName(name) : name }}</td>
                            <td><button type="button" class="btn btn-danger" @click="students.splice(students.indexOf(name),1);studentsRemoved++;studentAddIndex=students.length+1"><i class="bi bi-person-dash"></i>{{isMobileDevice ? '' : ' Удалить'}}</button></td>
                            <!-- <button type="button" class="btn btn-success my-1 mx-1" @click="students.splice(students.indexOf(name),1);studentsRemoved++;studentAddIndex=students.length+1"><i class="bi bi-bar-chart"></i>{{isMobileDevice ? '' : ' Статистика'}}</button> -->
                        </tr>
                    </tbody>
                </table>
                <p class="text-body text-center" v-if="foundedStudents.length==0">Ученики не найдены</p>
            </div>
        </div>
    </div>
    <div v-else-if="manageType==1">
        <div class="mb-3">
            <button type="button" class="btn btn-primary mx-1 my-1" @click="updateStudensListText" :disabled="studentsList.length < 10 || studentsListOld == studentsList || !noEmptyLines"><i class="bi bi-floppy"></i> Сохранить</button>
            <button type="button" class="btn btn-danger mx-1 my-1" @click="studentsList = studentsListOld" :disabled="studentsListOld == studentsList"><i class="bi bi-arrow-return-left"></i> Восстановить</button>
            <button type="button" class="btn btn-warning mx-1 my-1" @click="studentsList = ''"><i class="bi bi-backspace"></i> Очистить</button>
            <button type="button" class="btn btn-success mx-1 my-1" @click="copyText" :disabled="copyButton != 'Скопировать текст'"><i class="bi bi-clipboard"></i> {{copyButton}}</button>
            <div class="form-text" v-if="diffLines != 0">Новый список {{diffLines > 0 ? 'больше' : 'меньше'}} сохранённого на {{ Math.abs(diffLines) }} строки</div>
        </div>
        <div class="alert alert-warning" role="alert" v-if="!noEmptyLines">
            <i class="bi bi-exclamation-circle"></i> В списке есть пустые строки! Их необходимо удалить!
        </div>
        <div class="mb-3">
            <label for="studentList" class="form-label text-body">Список учеников (Новый ученик c новой строки)</label>
            <textarea v-model="studentsList" class="form-control" :rows="students.length" id="studentList"></textarea>
        </div>
    </div>
</div>
</template>

<style>
</style>