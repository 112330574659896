import jsCookie from 'js-cookie';
import { httpGet } from './request';

export const CLASS_LET = ['П','Р','К','М','Б']
export const CLASS_NUM = ['8', '9', '10', '11']
export const LET_NAMES = {
    'П': 'Программирование',
    'Р': 'Робототехника',
    'К': 'Культура',
    'М': 'Медицина будущего',
    'Б': 'Биотехнология'
}

const gardesPre = []
for (let num of CLASS_NUM) {
    for (let lett of CLASS_LET) {
        gardesPre.push(`${num}${lett}`)
    }
}

export const GRADES = gardesPre

export const EVENT_TYPES = [
    'Всероссийская олимпиада школьников',
    'Научно-практические конференции',
    'Заочные и дистанционные конкурсные мероприятия',
    'Очные интеллектуальные конкурсные мероприятия',
    'Мероприятия и конкурсы художественно-эстетической направленности',
    'Спортивные состязания',
    'Социально-значимые мероприятия',
    'Военно-патриотические, экологические, туристско-краеведческие, поисковые мероприятия'
]
export const EVENT_RESULTS = [
    'Победитель',
    'Призёр',
    'Участник',
    'Организатор'
]

export const EVENT_LEVELS = [
    'Школьный',
    'Муниципальный',
    'Региональный',
    'Всероссийский',
    'Международный'
]

export const PERM_NAMES = {
    0: 'Разработчик',
    1: 'Администратор',
    2: 'Менеджер',
    3: 'Проверяющий'
}

export function copyObj(object) {
    return JSON.parse(JSON.stringify(object))
}

export function shortName(name) {
    let arr = name.split(' ')
    return `${arr[0]} ${arr[1] ? `${arr[1].split('')[0]}. ${arr[2] ? `${arr[2].split('')[0]}.` : '' }` : ''}`
}

export function getStudentLet(grade) {
    if (grade.length == 2) {
        return grade[1]
    }
    return grade[2]
}

export function getTheme() {
    if (jsCookie.get('theme') != undefined) return jsCookie.get('theme') == 'true'
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        return true
    }
    return false
}

export function themeSwitch() {
    jsCookie.set('theme',!getTheme(),{expires:365})
    location.reload();
}

export function getStudentNum(grade) {
    if (grade.length == 2) {
        return grade[0]
    }
    return parseInt(`1${grade[1]}`)
}


export function convertBytes(bytes) {
    const sizes = ['Б', 'КБ', 'МБ', 'ГБ', 'ТБ'];
    if (bytes == 0) return '0 Б';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return (bytes / Math.pow(1024, i)).toLocaleString(undefined,{maximumFractionDigits:2}) + ' ' + sizes[i];
}

export async function getStudents() {
    let version = (await httpGet('data/students_version.json',true,'0',true)).data
    let array = (await httpGet(`data/students.json?v=${version}`,true,[])).data
    return array
}

export function isMobile() {
    return screen.width <= 480
}


export function getDateDMY() {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();

    return `${day}.${month}.${year}`;
}